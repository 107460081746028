import { createRoot } from 'react-dom/client';
import App from './App.jsx';
import './index.css';
import './tailwind.css';
import { store, persistor } from './redux/store.js';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { LanguageContextProvider } from './utils/context/LanguageContext.tsx';
import './i18n/i18n';
import React from 'react';
import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga4';
import { tryLoadAndStartRecorder } from '@alwaysmeticulous/recorder-loader';

const TRACKING_ID = import.meta.env.VITE_GOOGLE_ANALYTICS_ID; // your Measurement ID

// Sentry initialization
Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.metrics.metricsAggregatorIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  environment: import.meta.env.VITE_SENTRY_ENV,
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

// Function to check if the environment is production
function isProduction() {
  return window.location.hostname.indexOf('adrushes.com') > -1;
}

// Google Analytics initialization
const AnalyticsInitializer = () => {
  React.useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    ReactGA.send({ hitType: 'pageview', page: '/', title: 'Landing Page' });
  }, []);

  return null; // This component doesn't render anything
};

// Meticulous recorder initialization and app rendering
async function startApp() {
  if (!isProduction()) {
    await tryLoadAndStartRecorder({
      recordingToken: import.meta.env.VITE_METICULOUS_ID,
      isProduction: false,
    });
  }

  const root = createRoot(document.getElementById('root'));
  root.render(
    <PersistGate persistor={persistor}>
      <Provider store={store}>
        <LanguageContextProvider>
          <AnalyticsInitializer />
          <App />
        </LanguageContextProvider>
      </Provider>
    </PersistGate>,
  );
}

// Start the app
startApp();
