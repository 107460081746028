import React, { FC, ReactNode } from 'react';
import { Modal } from 'antd';

interface CustomModalProps {
  title?: string;
  visible: boolean;
  footer: boolean;
  onOk: () => void;
  onCancel: () => void;
  okText?: string;
  cancelText?: string;
  children?: ReactNode;
  width?: number;
}

export const CustomModal: FC<CustomModalProps> = ({
  title,
  visible,
  onOk,
  onCancel,
  okText,
  cancelText,
  children,
  footer,
  width =700 ,
}) => {
  return (
    <Modal
      
      title={title}
      open={visible}
      onOk={onOk}
      onCancel={onCancel}
      okText={okText}
      cancelText={cancelText}
      footer={footer}
       width ={width}
    >
      {children}
    </Modal>
  );
};
