import { BrowserRouter } from 'react-router-dom';
import '../tailwind.css';
import AuthProvider from 'react-auth-kit';
import createStore from 'react-auth-kit/createStore';
import Routes from '../routes/index.jsx';
import InstallPrompt from '../components/InstallPrompt.jsx';

const store = createStore({
  authName: '_auth',
  authType: 'localstorage',
  cookieDomain: window.location.hostname,
  cookieSecure: window.location.protocol === 'https:',
});

const Content = () => {
  return (
    <AuthProvider store={store}>
      <BrowserRouter>
        <InstallPrompt />
        <Routes />
      </BrowserRouter>
    </AuthProvider>
  );
};

export default Content;
