import { FC, ReactNode } from 'react';
import { Card } from 'antd';

interface CustomCardProps {
  size?: 'default' | 'small';
  title?: string;
  hoverable?: boolean;
  cover?: ReactNode;
  style?: CSSProperties;
  children: ReactNode;
}

export const CustomCard: FC<CustomCardProps> = ({
  size = 'small',
  title = '',
  children,
  hoverable,
  cover,
  style,
  ...others
}) => {
  return (
    <Card
      size={size}
      title={title}
      hoverable={hoverable}
      cover={cover}
      style={style}
      {...others}
    >
      {children}
    </Card>
  );
};
