import React from 'react';
import { Tooltip } from 'antd';
import { ReactNode } from 'react';

interface CommonTooltipProps {
  title: string;
  children?: ReactNode;
  bgColor?: string;
  textColor?: string;
  placement?: string;
}
export const CommonTooltip: React.FC<CommonTooltipProps> = ({
  title,
  children,
  bgColor = 'white',
  textColor = 'text-black',
  placement,
}) => {
  return (
    <Tooltip
      title={title}
      overlayInnerStyle={{
        backgroundColor: bgColor.replace('bg-', ''),
        color: textColor.replace('text-', ''),
      }}
      placement={placement}
    >
      {children}
    </Tooltip>
  );
};

export default CommonTooltip;
