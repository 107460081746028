/* This Functions is to convert the timestring to local 12hr format */
export const populate12HrFormat = (timeString: string) => {
  const date = new Date(timeString);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // Handle midnight (0 hours)

  const formattedTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;

  return formattedTime;
};

/* This function converts the timeString to a formatted date and time string */
export const formatDateTime = (timeString: string) => {
  const date = new Date(timeString);

  // Get the date parts
  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  };
  const formattedDate = date.toLocaleDateString('en-US', options);

  // Get the time parts
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // Handle midnight (0 hours)
  const formattedTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes} ${ampm}`;

  return `${formattedDate} | ${formattedTime}`;
};
