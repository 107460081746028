import { useEffect, useState } from 'react';
import { Spinner, Button } from '../../utils/antd-components/index.tsx';
// import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import {
  signupSuccess,
  signInStart,
  signInStop,
  signInFailure,
} from '../../redux/user/userSlice.js';
import { ApiUtils } from '../../utils/api.jsx';
import PATHS from '../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../utils/constants/MethodTypes.tsx';
import { message, Typography, Input, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { sendPageView } from '../../utils/analytics';

export default function SignIn() {
  const { loading, signup } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [passwordVisible] = useState(false);
  const [otpInput, setOtpInput] = useState(''); // State for OTP input

  useEffect(() => {
    sendPageView(window.location.pathname, 'Verify Email');
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Code:', otpInput);
    if (!otpInput) {
      return dispatch(signInFailure(t('please-fill-all-the-fields')));
    }
    if (!signup?.username) {
      return dispatch(signInFailure(t('email-not-found')));
    }
    try {
      dispatch(signInStart());
      const payload = {
        confirmationCode: otpInput,
        email: signup?.email,
      };

      console.log('payload:', payload);
      const URL = PATHS.AUTH.VERIFYEMAIL;
      const data = await ApiUtils(URL, payload, METHOD_TYPES.POST);
      if (data.status !== 200) {
        message.error(data.message);
        dispatch(signInFailure(data.message));
      }

      if (data.status === 200) {
        message.success(t('sign-up-successfully'));
        dispatch(signupSuccess(null));
        setTimeout(() => {
          navigate('/sign-in');
        }, 1000);
      }
    } catch (error) {
      dispatch(signInFailure(error.message));
    }
  };

  const resendCode = async () => {
    try {
      dispatch(signInStart());
      const payload = { email: signup?.email };
      console.log('payload:', payload);
      const URL = PATHS.AUTH.RESENDCODE;
      const data = await ApiUtils(URL, payload, METHOD_TYPES.POST);
      console.log(data);

      if (data.status === 200) {
        dispatch(signInStop());
        setOtpInput(''); // Clear OTP input on successful resend
      } else {
        dispatch(signInFailure(data.message));
      }
    } catch (error) {
      dispatch(signInFailure(error.message));
    }
  };

  const handleOtpChange = (e) => setOtpInput(e.target.value); // Update OTP input

  return (
    <div className="flex justify-center h-screen flex-col gap-4 items-center ">
      <Typography className="font-poppins text-xl ">
        {t('a-verification-code-has-been-sent-to-your-email-0')}
      </Typography>
      <Space direction="horizontal">
        <Input.Password
          value={otpInput} // Set input value to otpInput state
          onChange={handleOtpChange} // Handle OTP change
          mask={passwordVisible ? false : '🔒'}
          style={{ borderColor: '#0f2ec4' }}
        />
      </Space>
      <Button onClick={handleSubmit} disabled={loading}>
        {loading ? (
          <>
            <Spinner size="small" />
            <span className="pl-3">{t('verifying')}</span>
          </>
        ) : (
          <p className="">{t('verify-0')}</p>
        )}
      </Button>
      <Typography variant="h1" className="font-poppins">
        {t('enter-the-6-digit-code-sent-to-your-email-0')}
      </Typography>
      <div
        className="flex text-xs items-center font-normal cursor-pointer flex-col hover:text-blue-500 hover:bo hover:drop-shadow-glow text-[#0f2ec4]"
        onClick={resendCode}
      >
        {t('did-not-receive-the-code-resend-verification-code')}
      </div>
    </div>
  );
}
