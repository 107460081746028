import {
  Image,
  Table,
  Tag,
  Popconfirm,
  Button,
} from '../utils/antd-components/index.tsx';
import { useEffect, useState } from 'react';
import { ApiUtils } from '../utils/api.jsx';
import { useTranslation } from 'react-i18next';
//import UserSidebar from '../layouts/UserSidebar';
import PATHS from '../utils/constants/Paths.tsx';
import METHOD_TYPES from '../utils/constants/MethodTypes.tsx';
import Header from '../layouts/Header.jsx';
import DOMPurify from 'dompurify';
import Footer from '../layouts/Footer.jsx';
import loadingIcon from '../assets/image/mic_gif.gif';
import logoIcon from '../assets/image/Adrushes-logo.svg';
// import { sendPageView } from '../utils/analytics';
import dayjs from 'dayjs';
import routesnkt from '../routes/routes.json';
import { useNavigate } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { HomeOutlined, InboxOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Tooltip } from '../utils/antd-components/index.tsx';
import { MdDeleteOutline } from 'react-icons/md';

export default function UserAds() {
  const { t } = useTranslation();

  const [usersList, setUsersList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [expandedRows, setExpandedRows] = useState({});

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [count, setCount] = useState(0);

  useEffect(() => {
    fetchUsersAds();
  }, [currentPage]);

  const fetchUsersAds = async () => {
    try {
      setIsLoading(true);
      const URL = `${PATHS.ADS.GET}?page=${currentPage}&limit=${pageSize}`;
      const data = await ApiUtils(URL, '', METHOD_TYPES.GET);
      if (data.status === 200) {
        setIsLoading(false);
        setUsersList(data.data.ads);
        setCount(data.data.count);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error.message);
    }
  };
  const navigate = useNavigate();

  const handleEditAd = (value) => {
    navigate(routesnkt.editad, { state: { value } });
  };

  const toggleShowMore = (key) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };
  const handlePageChange = (page) => {
    setCurrentPage(page); // Trigger data fetch on page change
  };
  const uniqueCategories = Object.values(
    usersList.reduce((acc, item) => {
      const { _id, uid, name, status } = item.category;
      if (!acc[_id]) {
        acc[_id] = { _id, uid, name, status };
      }
      return acc;
    }, {}),
  );
  const categorydata = uniqueCategories.map((item) => ({
    text: item.name,
    value: item.uid,
  }));
  const handleDeleteUser = async (id) => {
    try {
      const URL = `${PATHS.ADS.DELETE}/${id}`;
      const data = await ApiUtils(URL, { status: 3 }, METHOD_TYPES.DELETE);
      if (data.status === 200) {
        fetchUsersAds();
      } else {
        console.log(data.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const renderDescription = (text, record) => {
    const sanitizedText = DOMPurify.sanitize(text);
    const wordLimit = 15;
    const words = sanitizedText.split(' ');

    if (words.length <= wordLimit) {
      return <div dangerouslySetInnerHTML={{ __html: sanitizedText }} />;
    }

    const truncatedText = words.slice(0, wordLimit).join(' ');

    return (
      <div>
        <div
          dangerouslySetInnerHTML={{
            __html: expandedRows[record.key]
              ? sanitizedText
              : truncatedText + '...',
          }}
        />
        <Button
          type="link"
          onClick={() => toggleShowMore(record.key)}
          style={{ padding: 0 }}
        >
          {expandedRows[record.key] ? t('show-less') : t('show-more')}
        </Button>
      </div>
    );
  };

  const columns = [
    {
      title: t('photo'),
      dataIndex: 'photo',
      key: 'photo',
      filteredValue: null,
      render: (text, record) =>
        record.photo ? (
          <Image width={50} height={50} src={text && text} />
        ) : (
          <img src={logoIcon} alt="The ICON" className="h-12 " />
        ),
    },
    {
      title: t('add-id'), // Displays "Add-id" or its translation in Tamil
      dataIndex: 'uid', // Use _id as the data index to display the Ad ID
      key: 'ad_id', // Use a unique key for this column
      render: (text) => (
        <Tag active="9"> {text} </Tag> // Display the Ad ID in a blue tag
      ),
      // Optional: You can set filters if needed, based on ad data
      onFilter: (value, record) => {
        return record.uid === value; // Filter logic based on the Ad ID
      },
    },

    {
      title: t('category'),
      dataIndex: 'category.name', // You can also use the full object
      key: 'category',
      render: (text, record) => (
        <Tag active="8"> {record?.category?.name} </Tag>
      ),
      filters: categorydata,
      onFilter: (value, record) => {
        return record.category.uid === value;
      },
    },
    {
      title: t('status-0'),
      dataIndex: 'status',
      key: 'status',
      render: (text) =>
        text === 1 ? (
          <Tag active="1">{t('active')}</Tag>
        ) : text === 2 ? (
          <Tag active="5">{t('yet-to-approve')}</Tag>
        ) : (
          <Tag active="3">{t('deleted')}</Tag>
        ),
      filters: [
        { text: 'Active', value: 1 },
        { text: 'Inactive', value: 2 },
        { text: 'Deleted', value: 3 },
      ],
      onFilter: (value, record) => {
        return record.status === value;
      },
    },
    {
      title: t('created Date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => dayjs(createdAt).format('DD-MM-YYYY'),
      sorter: (record1, record2) => {
        return record1.createdAt < record2.createdAt;
      },
    },
    {
      title: t('expiry Date'),
      key: 'expiryDate',
      render: (text, record) => {
        const expiryDate = dayjs(record.createdAt).add(30, 'days');
        return expiryDate.format('DD-MM-YYYY');
      },
      sorter: (record1, record2) => {
        return record1.expiryDate < record2.expiryDate;
      },
    },
    {
      title: t('title-0'),
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => (record.photo ? '-------' : text || null),
    },
    {
      title: t('description'),
      dataIndex: 'descp',
      key: 'descp',
      render: (text, record) =>
        record.photo ? '-------' : renderDescription(text, record),
      className: 'description-column',
    },
    {
      title: t('edit_Ads'),
      dataIndex: 'edit',
      key: 'edit',
      render: (text, record) => (
        <Button
          className="bg-[#ffffff] text-[#0F2EC4] font-bold shadow-lg hover:shadow-xl transition duration-300 p-2 rounded"
          onClick={() => handleEditAd(record)}
          type="primary"
        >
          {t('edit')}
        </Button>
      ),
    },
    {
      title: t('delete-ads-0'),
      key: 'delete',
      render: (text, record) => (
        <div style={{ display: 'flex', gap: '10px' }}>
          {record.status !== 3 && (
            <Popconfirm
              title={t('are-you-delete-this')}
              onConfirm={() => handleDeleteUser(record._id)}
              okText={t('yes-im-sure')}
              cancelText={t('No-cancel')}
            >
              <span className="cursor-pointer">
                <Tooltip title="Delete">
                  <MdDeleteOutline size={20} color="red" />
                </Tooltip>
              </span>
            </Popconfirm>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="bg-[#c9edfaa7] flex flex-col min-h-screen ">
      <Header />
      <div className="ml-3 mt-5">
        {' '}
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">
              <HomeOutlined className="text-[#0F2EC4]" />
              <span className="ml-1">{t('home')}</span>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {' '}
            <InboxOutlined className="text-[#0F2EC4]" />
            <span className="ml-1">{t('your-ads')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="flex flex-grow w-full h-[89%] p-3 lg:p-8 gap-8">
        <>
          <div className="lg:w-full h-full pt-4 table-auto overflow-y-scroll lg:overflow-x-hidden md:mx-auto scrollbar scrollbar-track-slate-100 scrollbar-thumb-slate-300 dark:scrollbar-track-slate-700 dark:scrollbar-thumb-slate-500">
            {t('your ads count')}
            <span className="font-bold text-black dark:text-yellow-400">
              {' '}
              {count}
            </span>
            <Table
              dataSource={usersList.map((item, index) => ({
                ...item,
                key: item._id || index,
              }))}
              columns={columns}
              loading={isLoading}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: count,
                onChange: handlePageChange,
              }}
            />
          </div>
        </>
      </div>
      {isLoading && (
        <div className="absolute w-screen h-screen flex justify-center items-center inset-0 bg-white bg-opacity-50">
          <img
            src={loadingIcon}
            alt="Loading..."
            className="h-[120px] w-[120px]"
          />
        </div>
      )}
      <Footer />
    </div>
  );
}
