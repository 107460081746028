import { useState } from 'react';
import { Upload, Button, message } from 'antd';
import { FaUpload, FaImages } from 'react-icons/fa';
import { ApiUtils } from '../utils/api.jsx';
import METHOD_TYPES from '../utils/constants/MethodTypes.tsx';
import PATHS from '../utils/constants/Paths.tsx';
import _ from 'lodash';
import Compressor from 'compressorjs';
const ImageUpload = (props) => {
  const [file, setFile] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  const formData = _.get(props, 'formData', null);
  const setFormData = _.get(props, 'setFormData', () => {});
  const loading = _.get(props, 'loading', () => {});
  const type = _.get(props, 'type', 'single');
  const listType = _.get(props, 'listType', 'picture');

  const handleSelectUploadImage = async (info) => {
    if (isUploading) return;

    const { file, fileList } = info;
    if (file.status === 'removed') {
      setFile([]);
      return;
    }

    setIsUploading(true);
    loading(true);
    const filteredFiles = fileList.filter((f) => {
      const isLt10M = f.size / 1024 / 1024 < 11;
      if (!isLt10M) {
        message.error('File must be smaller than 10MB');
        return false;
      }
      return true;
    });

    if (type === 'multiple' && filteredFiles.length > 10) {
      message.error('Cannot Upload more than 10 Images');
      setIsUploading(false);
      loading(false);
      return;
    }

    if (filteredFiles.length === 0) {
      message.error('Please Select the Image');
      setIsUploading(false);
      loading(false);
      return;
    }

    setFile(filteredFiles);
    //message.error(null);
    setIsUploading(false);
    loading(false);
  };

  const onRemoveImage = () => {
    setFile([]);
  };

  const handleUploadImage = async () => {
    setIsUploading(true);
    if (!file || file.length === 0) {
      message.error('Please Select the File');
      setIsUploading(false);
      return;
    }

    const formDataUpload = new FormData();

    try {
      const compressAndAppend = async (file) => {
        return new Promise((resolve, reject) => {
          new Compressor(file.originFileObj, {
            quality: 0.8,
            success: (compressedResult) => {
              formDataUpload.append('file', compressedResult, file.name);
              resolve();
            },
            error: (err) => {
              reject(err);
            },
          });
        });
      };

      await Promise.all(file.map((f) => compressAndAppend(f)));

      const data = await ApiUtils(
        PATHS.ADS.IMAGE,
        formDataUpload,
        METHOD_TYPES.POST,
        'multipart/form-data',
      );

      if (data.status === 200) {
        const uploadedImage =
          type !== 'multiple'
            ? data.data // Single image upload
            : data.data.map((image) => image.location);
        setFormData(uploadedImage);
        setIsUploading(false);
        message.success('Image Upload Successful');
      }
    } catch (error) {
      console.error('Error in upload image function', error);

      // Extracting the response message if available
      const errorMessage =
        error?.response?.data?.message ||
        'Image Upload failed. Please try again.';

      message.error(errorMessage); // Show the extracted message in the toast
    } finally {
      setIsUploading(false);
    }
  };

  // const onDeleteImage = async (image) => {
  //   const payload = { image };
  //   const URL = PATHS.IMAGE.DELETE;
  //   const data = await ApiUtils(URL, payload, METHOD_TYPES.DELETE);
  //   if (data.status === 200) {
  //     setFormData(null);
  //   }
  // };

  return (
    <div className="w-full">
      <div className="flex w-full gap-4 items-center justify-center border p-3">
        <Upload
          accept="image/*"
          onChange={handleSelectUploadImage}
          listType={listType}
          fileList={file}
          maxCount={1}
          customRequest={() => {}} // This line ensures no actual upload is attempted automatically
          beforeUpload={() => false} // This prevents the automatic file dialog from being triggered
          onRemove={onRemoveImage}
          className="w-full "
        >
          {!formData?.imageUrl && (
            <Button
              disabled={isUploading || formData?.imageUrl}
              icon={<FaImages />}
              className="px-2 py-2"
            >
              {type === 'multiple' ? 'Select Images' : 'Select Image'}
            </Button>
          )}
        </Upload>
        <Button
          disabled={isUploading || formData?.imageUrl}
          onClick={handleUploadImage}
          icon={<FaUpload />}
          loading={isUploading}
          className="px-2 py-2"
        >
          Upload Image
        </Button>
      </div>
    </div>
  );
};

export default ImageUpload;
