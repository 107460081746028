import { useEffect } from 'react';
import { Row, Col, Breadcrumb } from 'antd';
import CustomHeader from '../layouts/Header';
import Footer from '../layouts/Footer';
import { Link } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const PrivacyPolicyPage = () => {
  const { t } = useTranslation();
  // Control body overflow (for future use if needed)
  useEffect(() => {
    document.body.style.overflow = 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <div className="w-full flex flex-col bg-[#c9edfaa7] min-h-screen">
      <div className="z-20 p-0 sticky top-0 bg-[#ffffff]">
        <CustomHeader />
      </div>

      {/* Removed pt-10 to eliminate gap */}
      <div className="h-full flex-grow px-0 transition-opacity duration-300">
        {' '}
        {/* Adjusted padding */}
        <Row
          gutter={[0, 0]} // Set gutter to 0 for full width
          justify="center" // Center align the column
          className="h-full flex justify-center items-center" // Add h-full to Row
        >
          {/* Set Col to occupy full width */}
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            xxl={24}
            className="flex justify-center items-center gap-3 px-0"
          >
            <div className="bg-white p-6  shadow-lg w-full">
              {' '}
              {/* Set w-full */}
              <div className="ml-0">
                {' '}
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/">
                      <HomeOutlined className="text-[#0F2EC4]" />
                      <span className="ml-1">{t('home')}</span>
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>{t('privacy policy')}</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="w-2/3 mx-auto">
                <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
                <p className="mb-4">
                  At Adrushes, your privacy is very vital to us. This policy
                  summarises how we gather, use and secure our client
                  information when you interact with our website.
                </p>
                <p className="mb-4">
                  Adrushes is devoted to securing your private data. When you
                  provide us with recognisable information including user name,
                  email address, and other contact details, you can trust that
                  we will grip it meticulously by this privacy policy.
                </p>
                <p className="mb-4">
                  To improve your experience on our site, we will collect
                  information, improve our service, and communicate effectively
                  with you. Your information may be used for purposes like
                  processing your inquiries or sending updates that are
                  consistent with your interests.
                </p>
                <p className="mb-4">
                  We make sure that we implement appropriate security measures
                  to keep your data from unauthorized access or misuse. Adrushes
                  may update this privacy policy from time to time, so you need
                  to stay updated about the changes.
                </p>
                <p className="mb-4">
                  If you have any enquiries or concerns about this policy, feel
                  free to reach us. We value your trust and are committed to
                  securing your privacy when you engage with our service.
                </p>

                <h2 className="text-xl font-semibold mb-2">Data Collection</h2>
                <p className="mb-4">
                  To improve your experience and provide personalized service,
                  we may collect certain information. The data we collect
                  includes:
                </p>
                <ul className="mb-4 list-disc list-inside">
                  <li>User full name</li>
                  <li>Occupation</li>
                  <li>
                    Contact data (phone number, email address, and address)
                  </li>
                  <li>
                    Insights that help us conduct customer surveys and deliver
                    applicable advertising
                  </li>
                </ul>

                <h2 className="text-xl font-semibold mb-2">Security</h2>
                <p className="mb-4">
                  It is our top priority to protect your information. We have
                  advanced features like robust physical, electronic, and
                  administrative measures to protect your data from unauthorized
                  access or disclosure.
                </p>

                <h2 className="text-xl font-semibold mb-2">
                  Third-Party Links
                </h2>
                <p className="mb-4">
                  From time to time, Adrushes posts offers for products or
                  services from third parties on our website. Be aware that our
                  privacy rules do not apply to these external sites.
                </p>

                <h2 className="text-xl font-semibold mb-2">Contact Details</h2>
                <p className="mb-4">
                  Please contact us with any queries or concerns about this
                  policy at:
                </p>
                <p className="mb-4">Address: Puducherry</p>
                <p className="mb-4">Phone: +91 413-2233944</p>
                <p className="mb-4">
                  Email:{' '}
                  <a
                    href="mailto:info@netkathri.com"
                    className="text-blue-600 hover:underline"
                  >
                    info@netkathri.com
                  </a>
                </p>
                <p className="mt-4">
                  We appreciate your interest in Adrushes! We are committed to
                  giving you a trustworthy and educational experience.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <Footer />
    </div>
  );
};

export default PrivacyPolicyPage;
