import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cartCount: 0,
  error: null,
  loading: false,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCartEnableLoad: (state) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    addToCartDisableLoad: (state) => {
      state.loading = false;
      state.error = null;
      state.success = null;
    },
    addcartCount: (state, action) => {
      state.cartCount = action.payload;
    },
    paymentPageData: (state, action) => {
      state.subcriptionPlan = action.payload;
    },
  },
});

export const {
  addToCartEnableLoad,
  addToCartDisableLoad,
  addcartCount,
  paymentPageData,
} = cartSlice.actions;

export default cartSlice.reducer;
