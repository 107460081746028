import axios from 'axios';

let authToken = null;
let lang = null;

const getAuthToken = () => {
  if (!authToken) {
    authToken = localStorage.getItem('_auth');
  }
  return authToken;
};

const getLanguage = () => {
  if (!lang) {
    lang = localStorage.getItem('selectedLanguage') || 'en';
  }
  return lang;
};

const clearAuthToken = () => {
  authToken = null;
  localStorage.removeItem('_auth');
};

const clearLanguage = () => {
  lang = null;
};

const ApiUtils = async (
  url = '',
  payload = {},
  method = 'GET',
  contentType = 'application/json',
) => {
  try {
    const token = getAuthToken();
    //console.log('token>>>>:', token);
    const lang = getLanguage();
    const baseUrl =
      import.meta.env.VITE_ENV === 'development'
        ? import.meta.env.VITE_DEV_BASE_URL
        : import.meta.env.VITE_PROD_BASE_URL;

    // Append lang parameter to the URL for all types of requests
    let finalUrl = baseUrl + url;
    const hasQueryString = finalUrl.includes('?');

    // If URL already has query parameters, append the lang parameter, otherwise, initialize it.
    finalUrl += `${hasQueryString ? '&' : '?'}lang=${lang}`;

    const response =
      method == 'DELETE'
        ? await axios({
            method,
            url: finalUrl,
            headers: {
              'Content-Type': contentType,
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
            data: payload,
          })
        : await axios({
            method,
            url: finalUrl,
            headers: {
              'Content-Type': contentType,
              Authorization: `Bearer ${token}`,
            },
            withCredentials: true,
            data: payload,
          });

    return response.data;
  } catch (error) {
    console.log('error in api util:', error);

    const errorMessage = error.response?.data?.message || 'An error occurred';
    const status = error.response?.status || 500;

    return { message: errorMessage, status };
  }
};

export { ApiUtils, clearAuthToken, clearLanguage };
