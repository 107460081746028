import { Route } from 'react-router-dom';
import { Routes } from 'react-router-dom';
import LottieControl from '../utils/ui-components/lottie.tsx';
import { Suspense } from 'react';
import routesnkt from './routes.json';
import SignIn from '../pages/Authentication/SignIn.jsx';
import UserProfile from '../pages/UserProfile.jsx';
import SignUp from '../pages/Authentication/SignUp.jsx';
import HomePage from '../pages/AdsPage.jsx';
import Ads from '../pages/AdsPage.jsx';
import Contact from '../pages/Contact.jsx';
import UserVerify from '../pages/Authentication/UserVerify.jsx';
import PostAds from '../pages/AdPostsForm.jsx';
import ForgotPassword from '../pages/Authentication/ForgotPassword.jsx';
import OnlyAdminPrivateRoute from '../components/OnlyAdminPrivateRoute.jsx';
import AuthOutlet from '@auth-kit/react-router/AuthOutlet';
import UserAds from '../pages/UserAds.jsx';
import AdminAdsPage from '../pages/AdminAdsPage.jsx';
import AddCategories from '../pages/AddCategories.jsx';
import PinPage from '../pages/Pinpage.jsx';
import Privacypolicy from '../pages/Privacypolicy.jsx';
import Termsandcond from '../pages/Termsandcond.jsx';
import EditAd from '../pages/EditAd.jsx';
import MoreDetailsPage from '../pages/MoreDetailsPage.jsx';
import CronJobs from '../pages/CronJobs.jsx';

export default function App() {
  return (
    <Routes>
      <Route
        path={routesnkt.signIn}
        element={
          <Suspense fallback={<LottieControl />}>
            <SignIn />
          </Suspense>
        }
      />
      <Route path={routesnkt.signUp} element={<SignUp />} />
      <Route path={routesnkt.home} element={<HomePage />} />
      <Route path={routesnkt.getAds} element={<Ads />} />
      <Route path={routesnkt.getAdsCat} element={<Ads />} />
      <Route path={routesnkt.moreDetailsPage} element={<MoreDetailsPage />} />
      <Route path={routesnkt.pinpage} element={<PinPage />} />
      <Route path={routesnkt.contact} element={<Contact />} />
      <Route path={routesnkt.userVerify} element={<UserVerify />} />
      <Route path={routesnkt.forgotPassword} element={<ForgotPassword />} />

      <Route element={<AuthOutlet fallbackPath="/sign-in" />}>
        <Route path={routesnkt.cronJobs} element={<CronJobs />} />
        <Route path={routesnkt.profile} element={<UserProfile />} />
        <Route path={routesnkt.postads} element={<PostAds />} />
        <Route path={routesnkt.userAds} element={<UserAds />} />
        <Route path={routesnkt.allAds} element={<AdminAdsPage />} />
        <Route path={routesnkt.privacypolicy} element={<Privacypolicy />} />
        <Route path={routesnkt.editad} element={<EditAd />} />
        <Route path={routesnkt.termsandcond} element={<Termsandcond />} />
        <Route path={routesnkt.addCategory} element={<AddCategories />} />
      </Route>
      <Route element={<OnlyAdminPrivateRoute />}></Route>
      <Route path="*" element={<h1>Not Found</h1>} />
    </Routes>
  );
}
