import { Card } from '../utils/antd-components';
import Lottie from 'lottie-react';
import animation from '.././assets/lottie-json/contact.json';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer.jsx';
import { useEffect } from 'react';
import { sendPageView } from '../utils/analytics';

const Contact = () => {
  useEffect(() => {
    sendPageView(window.location.pathname, 'Contact');
  }, []);

  return (
    <div className="flex justify-center items-center flex-col h-screen w-full">
      <div className="w-full sticky top-0">
        <Header />
      </div>
      <div className="flex justify-center items-center w-full h-full  bg-[#f5f5f5]">
        <Card className="flex gap-4">
          <div className="flex justify-center items-center flex-row">
            <div className="w-1/2 h-full flex justify-center items-center">
              <Lottie
                animationData={animation}
                loop={false}
                className="w-full"
              />
            </div>
            <div className="flex justify-center items-center flex-col w-1/2 gap-2">
              <div className="text-3xl font-semibold w-full text-[#0f2ec4]">
                Contact Us
                <div className="font-normal text-base text-black">
                  If you have any questions or need support, feel free to reach
                  out to us at <strong>support@myads.com</strong>. We&apos;ll
                  get back to you as soon as possible!
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
      <div className="w-full">
        <Footer />
      </div>
    </div>
  );
};

export default Contact;
