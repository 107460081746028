import { useEffect, ReactNode, FC } from 'react';
import { message } from 'antd';

interface CustomMessageProps {
  successMessage?: string;
  type?: 'success' | 'warning' | 'error';
  position?: 'top' | 'bottom';
  icon?: ReactNode;
}

export const CustomMessage: FC<CustomMessageProps> = ({
  successMessage,
  type,
  position = 'top',
  icon,
}) => {
  useEffect(() => {
    showToast();
  }, [successMessage, type, position, icon]);
  const showToast = () => {
    const messageConfig = {
      content: successMessage,
      style: {} as React.CSSProperties,
      icon: icon,
    };

    if (position === 'bottom') {
      messageConfig.style.marginTop = '80vh';
    }

    if (type === 'success') {
      message.success(messageConfig);
    } else if (type === 'error') {
      message.error(messageConfig);
    } else if (type === 'warning') {
      message.warning(messageConfig);
    }
  };
  return null;
};
