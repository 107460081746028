import { FC, ReactNode } from 'react';
import { Carousel } from 'antd';

interface CustomCarouselProps {
  autoplay?: boolean;
  dotPosition?: 'top' | 'bottom' | 'left' | 'right';
  autoplaySpeed?: number;
  speed?: number;
  children?: ReactNode;
}

export const CustomCarousel: FC<CustomCarouselProps> = ({
  dotPosition,
  autoplay,
  autoplaySpeed,
  children,
  speed,
}) => {
  return (
    <Carousel
      autoplaySpeed={autoplaySpeed}
      dotPosition={dotPosition}
      autoplay={autoplay}
      speed={ speed }
    >
      {children}
    </Carousel>
  );
};
