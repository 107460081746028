import { useEffect, useState, useMemo } from 'react';
import { Row, Col } from 'antd';
import CustomHeader from '../layouts/Header';
import ContentCard from './ContentCard';
import Lottie from 'lottie-react';
import loadingIcon from '../assets/image/mic_gif.gif';
import METHOD_TYPES from '../utils/constants/MethodTypes';
import PATHS from '../utils/constants/Paths';
import { ApiUtils } from '../utils/api';
import Footer from '../layouts/Footer';
import { useSelector } from 'react-redux';
import { SmileTwoTone } from '@ant-design/icons';
import emptypageIcon from '../assets/lottie-json/Animation - 1729228149099.json';

const PinPage = () => {
  const [ads, setAds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Memoize the `booked` state and `cardId` to prevent unnecessary re-renders
  const card = useSelector((state) => state.user) || {};
  const booked = card.booked || {};

  const cardId = useMemo(() => {
    return Object.keys(
      Object.fromEntries(
        Object.entries(booked).filter(([, value]) => value === true),
      ),
    );
  }, [booked]);

  useEffect(() => {
    const fetchAds = async () => {
      if (cardId.length === 0) {
        setAds([]); // Handle the case where there are no pinned ads
        return; // Exit early if there are no card IDs
      }

      setIsLoading(true); // Set loading state before fetching data
      try {
        const URL = `${PATHS.ADS.LIST}?_id=${cardId.join(',')}&status=1`; // Join cardId array for URL
        const response = await ApiUtils(URL, METHOD_TYPES.GET);

        if (response?.status === 200) {
          setAds(response.data?.ads || []); // Default to an empty array if ads is undefined
        } else {
          console.error('Unexpected response:', response);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false); // Ensure loading state is updated in all cases
      }
    };

    fetchAds();
  }, [cardId]); // Only re-run if cardId changes

  // Control body overflow during loading
  useEffect(() => {
    document.body.style.overflow = isLoading ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isLoading]);

  return (
    <div className="w-full flex flex-col bg-[#c9edfaa7] min-h-screen ">
      <div className="z-20 p-0 sticky top-0 bg-[#ffffff]">
        <CustomHeader />
      </div>

      <div
        className={`h-full flex-grow pt-10 px-10 transition-opacity duration-300 ${isLoading ? 'blur-sm' : ''}`}
      >
        {ads.length > 0 ? (
          <Row
            gutter={[30, 30]}
            justify="start"
            className="py-10 flex justify-center items-center"
          >
            {ads.map((note) => (
              <Col
                key={note?._id}
                xs={24}
                sm={18}
                md={12}
                lg={8}
                xl={6}
                xxl={6}
                className="flex justify-center items-center gap-3"
              >
                <ContentCard note={note} />
              </Col>
            ))}
          </Row>
        ) : (
          !isLoading && (
            <div className="flex flex-col justify-center items-center h-full mt-40">
              <Lottie
                animationData={emptypageIcon}
                loop={true}
                className="h-40"
              />
              <p className="text-xl">
                Currently, there are no ads in this category &nbsp;{' '}
                <SmileTwoTone
                  twoToneColor="#FFD700"
                  className="text-2xl mr-8"
                />
              </p>
            </div>
          )
        )}
      </div>

      {isLoading && (
        <div className="absolute w-screen h-screen flex justify-center items-center inset-0 bg-white bg-opacity-50">
          <img
            src={loadingIcon}
            alt={'loading...'}
            className="h-[200px] w-[200px]"
          />
        </div>
      )}
      <Footer />
    </div>
  );
};

export default PinPage;
