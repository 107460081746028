import { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Radio, Form, message } from 'antd';
import {
  TextInput,
  Button,
  Typography,
  Card,
  Carousel,
  Modal,
  Select,
} from '../utils/antd-components/index.tsx';
import { CloseOutlined, RocketOutlined, EyeOutlined } from '@ant-design/icons';
import logoIcon from '../assets/image/Adrushes-logo.svg';
import routesnkt from '../routes/routes.json';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import animation from '../assets/lottie-json/PostAds.json';
import PATHS from '../utils/constants/Paths.tsx';
import { ApiUtils } from '../utils/api.jsx';
import ImageUpload from '../components/ImageUpload.jsx';
import METHOD_TYPES from '../utils/constants/MethodTypes.tsx';
import { useTranslation } from 'react-i18next';
import { sendPageView } from '../utils/analytics.jsx';
import DOMPurify from 'dompurify';

const PostAdsForm = () => {
  const navigate = useNavigate();
  const [modelOpen, setModelOpen] = useState(false);
  const [adType, setAdType] = useState('');
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [error, setError] = useState('');
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState('');

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const URL = PATHS.CATEGORY.GET;
      const response = await ApiUtils(URL, METHOD_TYPES.GET); // Specify GET method
      if (response.status === 200) {
        // Filter categories where status is 1
        const filteredCategories = response.data.filter(
          (category) => category.status === 1,
        );
        setCategories(filteredCategories); // Update state with filtered categories
      } else {
        console.error('Error fetching data:', response);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    sendPageView(window.location.pathname, 'Post Ads');
  });

  const handleImageUpload = (uploadedImage) => {
    form.setFieldsValue({
      photo: uploadedImage,
    });
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const payload = {
        title: values.title,
        category: category,
        descp: values.descp,
        type: adType,
        status: 2,
        // user: userDetails._id,
        photo: values.photo,
      };
      const URL = PATHS.ADS.TEXTPOST;
      const response = await ApiUtils(URL, payload, METHOD_TYPES.POST);
      if (response.status == 200) {
        message.success('Ad posted successfully!');
        setError(null);
        navigate(routesnkt.home);
      } else if (response.status === 405) {
        message.error(response.message);
      } else {
        message.error(t('failed-to-post-the-ad'));
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };

  return (
    <div className="w-screen h-screen">
      <div className="flex h-full flex-col lg:flex-row">
        <div className="lg:w-2/5 h-[40%] lg:h-full lg:rounded-l-none lg:rounded-r-[150px] rounded-b-[120px] flex flex-col items-center justify-center overflow-hidden bg-[#0f2ec4]">
          <Lottie
            animationData={animation}
            loop={false}
            className="flex h-3/6"
          />
          <div className="w-4/5 lg:w-full flex-col justify-between items-center">
            <Carousel
              autoplay
              autoplaySpeed={3000}
              speed={1500}
              className="!flex flex-row justify-between items-center"
            >
              {[
                t('create-your-ad-and-reach-the-right-audience'),
                t('advertise-with-ease-and-get-your-message-out-there'),
                t('post-your-ad-and-connect-with-potential-buyers'),
                t('let-your-ad-shine-by-start-posting-now'),
                t('promote-sell-or-rent-post-your-ad-in-minutes'),
              ].map((text, index) => (
                <div key={index}>
                  <div className="p-8 text-white text-center flex justify-center items-center font-medium font-poppins text-lg">
                    {text}
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>

        <div className="flex flex-col lg:px-24 px-6 w-full h-full lg:w-3/5 overflow-y-auto">
          <div className="flex items-center justify-between py-5">
            <div className="flex flex-row items-center justify-center gap-4">
              <img src={logoIcon} alt="The ICON" className="h-14" />
              <p className="text-2xl text-[#0f2ec4]">{t('adrushes')}</p>
            </div>
            <Button
              icon={<CloseOutlined />}
              type="text"
              onClick={() => navigate('/')}
            />
          </div>

          <Form form={form} layout="vertical">
            <Typography
              level={4}
              className="font-poppins flex mb-0"
              isRequired={false}
              value={t('post-your-ad-now')}
            />
            <Typography
              className="text-base font-poppins"
              isRequired={false}
              value={t(
                'fill-out-the-form-below-to-create-your-ad-it-only-takes-a-few-steps-to-reach-thousands',
              )}
            />

            <Form.Item
              name="category"
              label={t('category-0')}
              rules={[
                { required: true, message: t('please-select-a-category') },
              ]}
              className="pt-4"
            >
              <Select
                placeholder={t('please-select-a-category')}
                style={{ borderColor: '#0f2ec4' }}
                options={categories}
                id="_id"
                label="name"
                onChange={(value) => {
                  setCategory(value);
                  form.setFieldsValue({ category: value });
                }}
              />
            </Form.Item>

            <Form.Item name="type" label={t('type-0')}>
              <Radio.Group
                onChange={(e) => setAdType(e.target.value)}
                value={adType}
                style={{ borderColor: '#0f2ec4' }}
              >
                <Radio value={1}>{t('image-0')}</Radio>
                <Radio value={2}>{t('text')}</Radio>
              </Radio.Group>
            </Form.Item>

            {adType === 2 && (
              <>
                <Form.Item
                  name="title"
                  label={t('title-0')}
                  rules={[
                    { required: true, message: t('please-enter-a-title') },
                  ]}
                >
                  <TextInput placeholder={t('enter-your-title')} />
                </Form.Item>

                <Form.Item name="descp" rules={[{ required: true }]}>
                  <ReactQuill
                    theme="snow"
                    placeholder={t('Description is required')}
                    className="h-40 mb-12"
                    style={{ borderColor: '#0f2ec4' }}
                  />
                </Form.Item>

                {error && (
                  <div className="text-red-500 text-sm font-poppins">
                    {error}
                  </div>
                )}
              </>
            )}

            {adType === 1 && (
              <Form.Item
                name="photo"
                label={t('upload-image')}
                className="max-w-full "
                rules={[
                  { required: true, message: t('please-upload-a-image') },
                ]}
              >
                <ImageUpload setFormData={handleImageUpload} />
              </Form.Item>
            )}

            <div className="py-12 flex gap-20 justify-center place-items-center">
              <Button
                type="primary"
                icon={<EyeOutlined />}
                onClick={() => setModelOpen(true)}
                className="flex items-center gap-3 text-white font-poppins font-medium text-sm rounded-full bg-gradient-to-r from-[#0f2ec4] to-[#0f2ec4] py-2 px-4 normal-case transition hover:scale-105"
              >
                {t('preview')}
              </Button>

              <Modal
                title="Preview"
                visible={modelOpen}
                onCancel={() => setModelOpen(false)}
                footer={false}
              >
                <Card
                  title={form.getFieldValue('title')}
                  extra={
                    categories.find(
                      (category) =>
                        category.catId === form.getFieldValue('category'),
                    )?.catName
                  }
                >
                  <p>
                    {adType === 2 ? (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            form.getFieldValue('descp'),
                          ),
                        }}
                      />
                    ) : form.getFieldValue('photo') ? (
                      <img
                        src={form.getFieldValue('photo')}
                        alt="Preview"
                        style={{ maxWidth: '100%' }}
                      />
                    ) : (
                      t('nothing-to-preview')
                    )}
                  </p>
                </Card>
              </Modal>

              <Button
                type="primary"
                icon={<RocketOutlined />}
                onClick={handleSubmit}
                className="flex items-center gap-3 text-white font-poppins font-medium text-sm rounded-full bg-gradient-to-r from-[#0f2ec4] to-[#0f2ec4] py-2 px-4 normal-case transition hover:scale-105"
              >
                {t('post-ad')}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};
export default PostAdsForm;
