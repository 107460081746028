import React, { useState, useMemo } from 'react';
import { Badge, Tooltip, Modal, Button, Popover } from 'antd';
import { FaRegBookmark, FaBookmark } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { bookMark } from '../redux/user/userSlice';
import routesnkt from '../routes/routes.json';
import { ShareAltOutlined } from '@ant-design/icons'; // Add ShareAltOutlined Icon
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { FacebookIcon, TwitterIcon, WhatsappIcon } from 'react-share';
import { CopyFilled  } from "@ant-design/icons";


interface Note {
  _id: string;
  title: string;
  descp: string;
  category: { name: string };
  photo: string;
}

const ContentCard: React.FC<{ note: Note }> = ({ note }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false); // To manage the popover state
  const { title, descp, category, photo, _id } = note;
  
  const checkbookmark = useSelector((state: any) => state.user);
  const booked = checkbookmark?.booked || {};
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const modalExpand = () => setIsModalOpen(true);
  const handleOk = () => setIsModalOpen(false);
  const handleCancel = (e: React.MouseEvent) => {
    e.stopPropagation(); 
    setIsModalOpen(false);
  };

  const sanitizedHtml = useMemo(() => DOMPurify.sanitize(descp || ''), [descp]);
  const isContentLongEnough = sanitizedHtml.length > 395;

  const toggleBookmark = () => {
    if (_id) {
      const currentStatus = booked[_id];
      dispatch(bookMark([_id, !currentStatus]));
    }
  };

  const handleCardClick = () => {
    if (_id) {
      navigate(`${routesnkt.moreDetailsPages}${_id}`);
    }
  };

  // Create the URL for the share button (more details page)
  const adUrl = `${window.location.origin}${routesnkt.moreDetailsPages}${_id}`;

  // Share Menu
  const shareMenu = (
    <div
      style={{
        display: 'flex', // Flex layout for horizontal alignment
        gap: '16px', // Add spacing between icons
        padding: '10px', // Adjust padding around the menu
        borderRadius: '8px', // Rounded corners for the menu
        backgroundColor: '#f9f9f9', // Background color
      }}
    >
      <div
        onClick={(e) => {
          e.stopPropagation(); // Prevent card click
          setIsPopoverVisible(false); // Close the popover
        }}
        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      >
        <FacebookShareButton url={adUrl}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation(); // Prevent card click
          setIsPopoverVisible(false); // Close the popover
        }}
        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      >
        <TwitterShareButton url={adUrl}>
          <TwitterIcon size={32} round />
        </TwitterShareButton>
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation(); // Prevent card click
          setIsPopoverVisible(false); // Close the popover
        }}
        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      >
        <WhatsappShareButton url={adUrl}>
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
      </div>
      <div
        onClick={(e) => {
          navigator.clipboard.writeText(adUrl); // Copy URL to clipboard
          alert('URL copied to clipboard!');
          e.stopPropagation(); // Prevent card click
          setIsPopoverVisible(false); // Display alert
        }}
        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      >
        <CopyFilled style={{ fontSize: '30px', color: '#000' }} />
      </div>
    </div>
  );
  
  
  
  

  return (
    <div
      className="min-w-[200px] max-w-full max-h-[400px] min-h-[400px] rounded-lg transition hover:scale-105 bg-white shadow-md relative gap-5 cursor-pointer"
      onClick={handleCardClick}
    >
      <Badge.Ribbon text={category?.name} color="#0f2ec4">
        {photo ? (
          <div className="relative w-full h-full flex justify-center items-center">
            <img
              alt="cover"
              src={photo}
              className="w-full object-cover cursor-pointer rounded-lg"
              style={{
                height: '400px',
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
          </div>
        ) : (
          <div className="p-4 flex flex-col justify-start h-full">
            {title && (
              <div className="description-column">
                <Tooltip title={title}>
                  <h2 className="text-xl font-bold mb-2 pt-4 mr-2 w-64 truncate">
                    {title}
                  </h2>
                </Tooltip>
              </div>
            )}
            {descp && (
              <div>
                <div
                  className="description h-[250px] overflow-hidden"
                  dangerouslySetInnerHTML={{
                    __html: isContentLongEnough
                      ? sanitizedHtml.substring(0, 1000) + '...'
                      : sanitizedHtml,
                  }}
                />
                {isContentLongEnough && !isModalOpen && (
                  <button
                    className="text-blue-800 cursor-pointer mt-2"
                    onClick={(e) => {
                      e.stopPropagation();
                      modalExpand();
                    }}
                  >
                    Show more
                  </button>
                )}
                {isModalOpen && (
                  <Modal
                    title={title}
                    footer={false}
                    open={isModalOpen}
                    onOk={handleOk}
                    onCancel={handleCancel}
                  >
                    <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
                  </Modal>
                )}
              </div>
            )}
          </div>
        )}
      </Badge.Ribbon>

      <div
        className="absolute bottom-2 right-10 cursor-pointer p-1 rounded-full"
        onClick={(e) => {
          e.stopPropagation();
          toggleBookmark();
        }}
      >
        {booked[_id] === true ? (
          <FaBookmark style={{ fontSize: 20 }} color="blue" />
        ) : (
          <FaRegBookmark style={{ fontSize: 20 }} color="blue" />
        )}
      </div>

      {/* Share Button with Popover */}
      <div className="absolute bottom-2 left-4 sm:left-2">
  <Tooltip title="Share Post">
    <Popover
      content={shareMenu}
      trigger="click"
      placement="bottom"
      visible={isPopoverVisible}
      onVisibleChange={setIsPopoverVisible}
      className="flex flex-row"
    >
      <Button
        icon={<ShareAltOutlined />}
        shape="circle"
        size="large"
        className="bg-white text-blue-900 hover:bg-blue-300 shadow-lg p-2"
        onClick={(e) => e.stopPropagation()}
      />
    </Popover>
  </Tooltip>
</div>


    </div>
  );
};

export default React.memo(ContentCard);
