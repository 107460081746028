import { useState, useEffect } from 'react';
import Header from '../layouts/Header';
//import UserSidebar from '../layouts/UserSidebar';
import {
  // Image,
  Popconfirm,
  TextInput,
  Table,
  Tag,
  Button,
  Card,
} from '../utils/antd-components';
import { Form } from 'antd';
import { ApiUtils } from '../utils/api';
import PATHS from '../utils/constants/Paths';
import METHOD_TYPES from '../utils/constants/MethodTypes';
import { Message } from '../utils/antd-components';
import { useTranslation } from 'react-i18next';
import Footer from '../layouts/Footer';
import loadingIcon from '../assets/image/mic_gif.gif';
import { PlusOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import { HomeOutlined, LockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Tooltip } from '../utils/antd-components/index.tsx';
import { MdOutlineDisabledByDefault } from 'react-icons/md';
import { MdDeleteOutline } from 'react-icons/md';

const AddCategories = () => {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [publishError, setPublishError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = async () => {
    try {
      const payload = {};
      const URL = PATHS.CATEGORY.GET;
      const data = await ApiUtils(URL, payload, METHOD_TYPES.GET);
      if (data.status !== 200) {
        setPublishError(data.message);
        setIsLoading(false);
      } else {
        setCategories(data.data);
        setPublishError(null);
        setIsLoading(false);
      }
    } catch (error) {
      setPublishError(t('something-went-wrong-0'));
      setIsLoading(false);
    }
  };

  const onFinish = async (e) => {
    setIsLoading(true);

    try {
      const payload = { ...e };
      const URL = PATHS.CATEGORY.ADD;
      const data = await ApiUtils(URL, payload, METHOD_TYPES.POST);
      if (data.status !== 200) {
        setPublishError(data.message);
        setIsLoading(false);
      } else {
        setSuccessMessage(data.message);
        setPublishError(null);
        form.resetFields();
        setIsLoading(false);
      }
    } catch (error) {
      setPublishError(t('something-went-wrong-0'));
      setIsLoading(false);
    }
    getCategory();
  };

  const handleMarkAsApproved = async (id) => {
    try {
      const URL = `${PATHS.CATEGORY.UPDATE}/${id}`;
      const data = await ApiUtils(URL, { status: 1 }, METHOD_TYPES.PUT);
      if (data.status !== 200) {
        console.log('error in update status post=====', data.message);
        return;
      } else {
        getCategory();
      }
    } catch (error) {
      console.log('error in status update post=====', error.message);
    }
  };
  const handleMarkAsInactive = async (id) => {
    try {
      const URL = `${PATHS.CATEGORY.UPDATE}/${id}`;
      const data = await ApiUtils(URL, { status: 2 }, METHOD_TYPES.PUT);
      if (data.status !== 200) {
        console.log('error in update status post=====', data.message);
        return;
      } else {
        getCategory();
      }
    } catch (error) {
      console.log('error in status update post=====', error.message);
    }
  };
  const handleDeletecategory = async (id) => {
    try {
      const URL = `${PATHS.CATEGORY.UPDATE}/${id}`;
      const data = await ApiUtils(URL, { status: 3 }, METHOD_TYPES.PUT);
      if (data.status !== 200) {
        console.log('error in update status post=====', data.message);
        return;
      } else {
        getCategory();
      }
    } catch (error) {
      console.log('error in status update post=====', error.message);
    }
  };

  const columns = [
    {
      title: t('category Name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('category Id'),
      dataIndex: 'uid',
      key: 'uid',
    },
    {
      title: t('status-0'),
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => (
        <>
          {status === 2 ? (
            <Popconfirm
              title={t('are-you-sure-you-want-to-approve-this-post')}
              onConfirm={() => handleMarkAsApproved(record._id)}
              okText={t('yes')}
              cancelText={t('no')}
            >
              <a
                href="#"
                className="font-medium text-red-500 hover:underline cursor-pointer"
              >
                {t('mark-as-approved')}
              </a>
            </Popconfirm>
          ) : status === 1 ? (
            <Tag active="1">{t('active')}</Tag>
          ) : status === 2 ? (
            <Tag active="2">{t('inActive')}</Tag>
          ) : (
            <Tag active="3">{t('deleted')}</Tag>
          )}
        </>
      ),
    },
    {
      title: t('delete-ads-0'),
      key: 'delete',
      render: (text, record) => (
        <div style={{ display: 'flex', gap: '10px' }}>
          {record.status === 1 && (
            <Popconfirm
              title={t('are-you-want-to-inactive')}
              onConfirm={() => handleMarkAsInactive(record._id)}
              okText={t('yes-im-sure')}
              cancelText={t('no-cancel')}
            >
              <span className="cursor-pointer">
                <Tooltip title="InActive">
                  <MdOutlineDisabledByDefault size={20} color="blue" />
                </Tooltip>
              </span>
            </Popconfirm>
          )}
          {record.status !== 3 && (
            <Popconfirm
              title={t('are-you-delete-this')}
              onConfirm={() => handleDeletecategory(record._id)}
              okText={t('yes-im-sure')}
              cancelText={t('No-cancel')}
            >
              <span className="cursor-pointer">
                <Tooltip title="Delete">
                  <MdDeleteOutline size={20} color="red" />
                </Tooltip>
              </span>
            </Popconfirm>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="w-full flex flex-col min-h-screen bg-[#c9edfaa7]">
      <Header />{' '}
      <div className="ml-3 mt-5">
        {' '}
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">
              <HomeOutlined className="text-[#0F2EC4]" />
              <span className="ml-1">{t('home')}</span>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {' '}
            <LockOutlined className="text-[#0F2EC4]" />
            <span className="ml-1">{t('add Categories')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="flex flex-grow justify-center items-center w-full h-[89%] p-3 lg:p-8 gap-8">
        {/* <UserSidebar /> */}
        <Card className="shadow-lg p-3  w-[110%] h-fit rounded-xl">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-[#000000] text-2xl font-semibold">
              {t('add Categories')}
            </h2>
          </div>

          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            className="flex flex-col gap-4 lg:gap-2"
          >
            <div className="flex  flex-row lg:flex-row-lg justify-between items-center gap-4">
              <Form.Item
                name="name"
                label={
                  <div className="flex items-center ">
                    <span className="font-semibold text-base pr-7">
                      {t('category Name')}
                    </span>
                  </div>
                }
                rules={[
                  { required: true, message: 'Please enter category name' },
                  {
                    pattern: /^[a-zA-Z\s\p{P}\p{S}]*$/u,
                    message: t('name-cannot-contain-numbers'),
                  },
                ]}
              >
                <TextInput
                  placeholder={t('enter Category Name')}
                  className="border-blue-600"
                />
              </Form.Item>

              <Button
                type="primary"
                htmlType="submit"
                icon={<PlusOutlined />}
                className="ml-2 bg-gradient-to-r from-blue-800 to-blue-800 rounded-full"
              >
                {t('add')}
              </Button>
            </div>
          </Form>

          <div className=" overflow-x-auto w-full  max-h-[80vh] flex justify-center">
            <Table
              columns={columns}
              dataSource={categories}
              pagination={false}
              className="w-full lg-flex justify-center"
              scroll={{ x: '100%', y: 200 }} // Adjust 'y' value to limit height
            />
          </div>
        </Card>
      </div>
      {publishError && <Message publishError={publishError} type="error" />}
      {successMessage && (
        <Message successMessage={successMessage} type="success" />
      )}
      {isLoading && (
        <div className="absolute w-screen h-screen flex justify-center items-center inset-0 bg-white bg-opacity-50">
          <img
            src={loadingIcon}
            alt="Loading..."
            className="h-[120px] w-[120px]"
          />
        </div>
      )}
      <Footer />
    </div>
  );
};

export default AddCategories;
