import { useEffect, useState, useCallback } from 'react';
import { List, Col, Row, Spin } from 'antd';
import VirtualList from 'rc-virtual-list';
import { useLocation } from 'react-router-dom';
import { SmileTwoTone } from '@ant-design/icons';
import Lottie from 'lottie-react';
import loadingIcon from '../assets/image/mic_gif.gif';
import emptypageIcon from '../assets/lottie-json/Animation - 1729228149099.json';
import CustomHeader from '../layouts/Header';
import ContentCard from './ContentCard';
import Footer from '../layouts/Footer';
import METHOD_TYPES from '../utils/constants/MethodTypes';
import PATHS from '../utils/constants/Paths';
import { ApiUtils } from '../utils/api';
import { sendPageView } from '../utils/analytics';

const ContainerHeight = 800;
const ItemHeight = 350;
const LIMIT = 9;

const chunkArray = (array, size) => {
  return Array.from({ length: Math.ceil(array.length / size) }, (_, i) =>
    array.slice(i * size, i * size + size),
  );
};

const renderEmpty = () => (
  <div className="flex flex-col justify-center items-center h-full mt-40">
    <Lottie animationData={emptypageIcon} loop={true} className="h-40" />
    <p className="text-xl">
      Currently, there are no ads in this category &nbsp;
      <SmileTwoTone twoToneColor="#FFD700" className="text-2xl mr-8" />
    </p>
  </div>
);

const AdsPage = () => {
  const location = useLocation();
  const [ads, setAds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const fetchAds = useCallback(async (categoryId = null, pageNum = 1) => {
    const loadingStateSetter = pageNum === 1 ? setIsLoading : setIsFetchingMore;
    loadingStateSetter(true);
    try {
      const queryParams = categoryId
        ? `?page=${pageNum}&limit=${LIMIT}&category=${categoryId}&status=1&expand=category`
        : `?page=${pageNum}&limit=${LIMIT}&status=1&expand=category`;
      const URL = `${PATHS.ADS.LIST}${queryParams}`;
      const response = await ApiUtils(URL, METHOD_TYPES.GET);

      if (response.status === 200) {
        const newAds = response.data.ads;
        setAds((prevAds) => (pageNum === 1 ? newAds : [...prevAds, ...newAds]));
        setHasMore(newAds.length === LIMIT);
      }
    } catch (error) {
      console.error('Error fetching ads:', error);
    } finally {
      loadingStateSetter(false);
    }
  }, []);

  useEffect(() => {
    const categoryId = location.state?._id;
    sendPageView(window.location.pathname, 'Sales');
    fetchAds(categoryId, 1); // Reset to page 1 on initial load
  }, [location, fetchAds]);

  useEffect(() => {
    document.body.style.overflow = isLoading ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isLoading]);

  const handleScroll = (e) => {
    if (
      e.target.scrollTop + e.target.clientHeight >=
        e.target.scrollHeight - 10 &&
      hasMore &&
      !isFetchingMore
    ) {
      setPage((prevPage) => {
        const nextPage = prevPage + 1;
        fetchAds(location.state?._id, nextPage);
        return nextPage;
      });
    }
  };

  return (
    <div className="flex flex-col bg-[#c9edfaa7] min-h-screen gap-x-4 gap-y-4">
      {' '}
      <header className="z-20 sticky top-0 bg-[#f5f5f5]">
        <CustomHeader />
      </header>
      <main className={`flex-grow pt-5 px-3`}>
        {ads.length ? (
          <List className="!border-none">
            <VirtualList
              data={chunkArray(ads, 4)}
              height={ContainerHeight}
              itemHeight={ItemHeight}
              itemKey={(row) => row.map((item) => item._id).join('-')}
              onScroll={handleScroll}
            >
              {(row) => (
                <List.Item key={row.map((item) => item._id).join('-')}>
                  <div className="flex-grow justify-center pt-5 px-7">
                    <Row gutter={[30, 30]} justify="space-evenly">
                      {row.map((item) => (
                        <Col
                          key={item._id}
                          xs={24}
                          sm={18}
                          md={12}
                          lg={8}
                          xl={6}
                          xxl={6}
                        >
                          <ContentCard note={item} />
                        </Col>
                      ))}
                    </Row>
                  </div>
                </List.Item>
              )}
            </VirtualList>
            {/* Loading spinner at the bottom during scroll-based loading */}
            {isFetchingMore && hasMore && (
              <div className="flex justify-center py-4">
                <Spin size="large" />
              </div>
            )}
          </List>
        ) : (
          !isLoading && renderEmpty()
        )}
      </main>
      {isLoading && (
        <div className="absolute w-screen h-screen flex justify-center items-center inset-0 bg-white bg-opacity-50">
          <img
            src={loadingIcon}
            alt="loading..."
            className="h-[200px] w-[200px]"
          />
        </div>
      )}
      <Footer />
    </div>
  );
};

export default AdsPage;
