import React, { createContext, useContext, useEffect, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { clearLanguage } from '../api';

// Import translation files
import enTranslations from '../../i18n/locales/en.json';
import taTranslations from '../../i18n/locales/ta.json';

// Initialize i18next
i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslations,
    },
    ta: {
      translation: taTranslations,
    },
  },
  lng: 'en', // Set default language
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

interface LanguageContextType {
  t: TFunction;
  i18n: {
    changeLanguage: (lang: string) => Promise<TFunction>;
  };
  onClickLanguageChange: (language: string) => void;
  LANGUAGELIST: Record<string, { nativeName: string }>;
}

export const LanguageContext = createContext<LanguageContextType | undefined>(
  undefined,
);

interface LanguageContextProviderProps {
  children: ReactNode;
}

export const LanguageContextProvider: React.FC<
  LanguageContextProviderProps
> = ({ children }) => {
  const { t, i18n } = useTranslation();

  const LANGUAGELIST: Record<string, { nativeName: string }> = {
    en: { nativeName: 'English' },
    ta: { nativeName: t('Tamil') },
  };

  useEffect(() => {
    const selectedLanguage = localStorage.getItem('selectedLanguage');
    if (selectedLanguage) {
      i18n.changeLanguage(selectedLanguage);
    }
  }, []);

  const onClickLanguageChange = (language: string) => {
    clearLanguage();
    i18n.changeLanguage(language);
    localStorage.setItem('selectedLanguage', language);
  };

  return (
    <LanguageContext.Provider
      value={{ t, i18n, onClickLanguageChange, LANGUAGELIST }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguageContext = () => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error(
      'useLanguageContext must be used within a LanguageContextProvider',
    );
  }
  return context;
};
