import { useState, useEffect } from 'react';
import { Spinner, Button } from '../../utils/antd-components/index.tsx';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  signInPending,
  signInFailure,
  signupSuccess,
} from '../../redux/user/userSlice.js';
import { ApiUtils } from '../../utils/api.jsx';
import routesnkt from '../../routes/routes.json';

import { RxEnter } from 'react-icons/rx';
import Lottie from 'lottie-react';
import Animation from '../../assets/lottie-json/SignIn Animation.json';
import PATHS from '../../utils/constants/Paths.tsx';
import { Typography, Input, Form, message } from 'antd';
import { Link } from 'react-router-dom';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { useTranslation } from 'react-i18next';
import { sendPageView } from '../../utils/analytics.jsx';
import { CloseOutlined } from '@ant-design/icons';
export default function SignUp() {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const authUser = useAuthUser();

  const userDetails = authUser ? authUser.userDetails : null;
  useEffect(() => {
    sendPageView(window.location.pathname, 'Sign Up');

    if (userDetails) {
      message.success(t('youre-are-logged-in'));
      navigate('/');
    } else if (userDetails === null) {
      navigate('/sign-up');
    }
  }, []);
  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const URL = PATHS.AUTH.SIGNUP;
      const data = await ApiUtils(URL, values, 'POST');
      if (data.status !== 200 && data.status !== 401) {
        dispatch(signInFailure(data.message));
        message.error(data.message);
      } else if (data.status === 401) {
        dispatch(signInPending(data));
      }
      console.log('data====', data);
      console.log('values====', values);
      if (data.status == 200) {
        dispatch(signupSuccess(data.data));
        navigate(routesnkt.userVerify);
      }
    } catch (error) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center w-full h-screen overflow-hidden">
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        initialValues={{ remember: true }}
        validateTrigger={['onBlur']}
        className="w-full lg:w-1/2 flex flex-row justify-center items-center" // Trigger validation only onBlur
      >
        <div className=" w-full lg:w-8/12 h-full p-4 border-none flex flex-row-reverse justify-center items-center ">
          <div className=" w-full">
            <div className="flex flex-row justify-between">
              <div className="flex flex-row py-4 justify-between items-center w-full">
                <p className="text-4xl font-medium text-[#0f2ec4]">
                  {t('sign-up-0')}{' '}
                </p>
                <Button
                  type="text"
                  className="text-black text-lg"
                  onClick={() => navigate(routesnkt.home)}
                  icon={<CloseOutlined />}
                />

                {/* <div>
                  <Typography
                    variant="lead"
                    color="black"
                    className="font-poppins text-base text-gray-800 py-3"
                  >
                    {t('sign-up-with-google')}
                  </Typography>

                  <GoogleLogin
                    type="standard"
                    shape="circle"
                    text="signin"
                    onSuccess={(credentialResponse) => {
                      const details = jwtDecode(credentialResponse.credential);
                      console.log(details);
                      console.log(credentialResponse);
                      navigate('/postads');
                    }}
                    onError={() => {
                      console.log('Login Failed');
                    }}
                  />
                </div> */}
              </div>
            </div>
            {/* <Divider style={{ borderColor: '#0f2ec4', color: '#0f2ec4' }}>
              Or
            </Divider> */}
            <div className="flex flex-col justify-between ">
              {/* Name field with onBlur validation */}
              <Form.Item
                name="name"
                label={t('your-name')}
                rules={[
                  {
                    required: true,
                    message: t('please-enter-your-name'),
                  },
                  {
                    pattern: /^[a-zA-Z\s]*$/,
                    message: t('name-cannot-contain-numbers'),
                  },
                  {
                    min: 6,
                    message: 'Name must be at least 6 characters long',
                  },
                ]}
                validateTrigger="onBlur"
              >
                <Input
                  id="name"
                  placeholder={t('Name')}
                  type="text"
                  className="font-poppins"
                  onChange={(e) =>
                    setFormData({ ...formData, name: e.target.value.trim() })
                  }
                  style={{ borderColor: '#0f2ec4' }}
                />
              </Form.Item>

              {/* Username field */}
              <Form.Item
                name="username"
                label={t('username-0')}
                rules={[
                  {
                    required: true,
                    message: t('please-enter-your-username'),
                  },
                  {
                    min: 6,
                    message: t('username-must-be-at-least-6-characters-long'),
                  },
                ]}
                validateTrigger="onBlur"
              >
                <Input
                  id="username"
                  placeholder={t('username-0')}
                  className="font-poppins"
                  type="text"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      username: e.target.value.trim(),
                    })
                  }
                  style={{ borderColor: '#0f2ec4' }}
                />
              </Form.Item>

              {/* Email field */}
              <Form.Item
                name="email"
                label={t('your-email')}
                rules={[
                  {
                    required: true,
                    message: t('please-enter-your-email'),
                  },
                  {
                    type: 'email',
                    message: t('please-enter-a-valid-email'),
                  },
                ]}
                validateTrigger="onBlur"
              >
                <Input
                  id="email"
                  placeholder={t('email-0')}
                  type="email"
                  className="font-poppins"
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value.trim() })
                  }
                  style={{ borderColor: '#0f2ec4' }}
                />
              </Form.Item>

              {/* Password field with regex validation */}
              <Form.Item
                name="password"
                label={t('password-0')}
                rules={[
                  {
                    required: true,
                    message: t('please-enter-your-password'),
                  },
                  {
                    pattern:
                      // eslint-disable-next-line no-useless-escape
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,30}$/,
                    message: t(
                      'password-must-be-at-least-8-characters-long-include-uppercase-lowercase-number-and-special-character',
                    ),
                  },
                ]}
                validateTrigger="onBlur"
              >
                <Input.Password
                  id="password"
                  placeholder={t('password-1')}
                  className="font-poppins"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      password: e.target.value.trim(),
                    })
                  }
                  style={{ borderColor: '#0f2ec4' }}
                />
              </Form.Item>
              <Button
                className="h-full flex justify-center  items-center "
                type="primary"
                htmlType="submit"
                disabled={loading}
                style={{ background: '#0f2ec4' }}
              >
                <RxEnter className="w-6 h-6" />
                {loading ? (
                  <>
                    <Spinner size="small" />
                    <span className="pl-3">{t('signing-up')}</span>
                  </>
                ) : (
                  <p className="font-poppins font-semibold">{t('sign-up-0')}</p>
                )}
              </Button>
              <Link to={routesnkt.signIn} className="p-2 ">
                <Typography className="text-center justify-center flex text-xs gap-1 w-full ">
                  {t('already-have-an-account')}
                  <button className="font-medium  text-[#0f2ec4] hover:underline">
                    {t('sign-in')}
                  </button>
                </Typography>
              </Link>
            </div>
          </div>
        </div>
      </Form>

      <div className="w-1/2 justify-center hidden md:flex lg:flex">
        <Lottie animationData={Animation} loop={false} className="w-full " />
      </div>
    </div>
  );
}
