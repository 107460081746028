import {
  Message,
  Button,
  TextInput,
  InputPassword,
  Typography,
} from '../../utils/antd-components/index.tsx';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Lottie from 'lottie-react';
import animation from '../../assets/lottie-json/SignIn Animation.json';
import { RxEnter } from 'react-icons/rx';
import { useDispatch, useSelector } from 'react-redux';
import {
  signInStart,
  signInPending,
  signInFailure,
  SignInRiderect,
} from '../../redux/user/userSlice.js';
import { useTranslation } from 'react-i18next';
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import { ApiUtils } from '../../utils/api.jsx';
import PATHS from '../../utils/constants/Paths.tsx';
import METHOD_TYPES from '../../utils/constants/MethodTypes.tsx';
import routesnkt from '../../routes/routes.json';
import { Form, message } from 'antd';
import { Link } from 'react-router-dom';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { sendPageView } from '../../utils/analytics';
import { CloseOutlined } from '@ant-design/icons';

export default function SignIn() {
  const signIn = useSignIn();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    error: errorMessage,
    success: successMessage,
    redirect,
  } = useSelector((state) => state.user);
  const authUser = useAuthUser();
  const userDetails = authUser ? authUser.userDetails : null;

  useEffect(() => {
    sendPageView(window.location.pathname, 'Sign In');
    window.scrollTo(0, 0);
    dispatch(signInPending(''));
    dispatch(signInFailure(''));
    if (userDetails) {
      <Message type="success" successMessage={t('youre-are-logged-in-0')} />;
      navigate(routesnkt.home);
    } else if (userDetails === null) {
      navigate('/sign-in');
    }
  }, [userDetails, navigate, dispatch]);

  const handleSubmit = async (values) => {
    setLoading(true);

    try {
      dispatch(signInStart());
      const URL = PATHS.AUTH.SIGNIN;
      const data = await ApiUtils(URL, values, METHOD_TYPES.POST);
      dispatch(signInPending(data));

      if (data.status !== 200 && data.status !== 401) {
        dispatch(signInFailure(data.message));
        setLoading(false);
      } else if (data.status === 401) {
        navigate(routesnkt.userVerify);
        setLoading(false);
      }

      if (data.status === 200) {
        message.success(t('sigin in successfully'));
        if (
          signIn({
            auth: {
              token: data.access_token,
              type: 'Bearer',
            },
            userState: {
              name: data.user.username,
              _id: data.user._id,
              userDetails: data.user,
              email: data.user.email,
              roles: data.user.roleId._id,
            },
          })
        ) {
          if (redirect) {
            let redirects = redirect;
            dispatch(SignInRiderect(''));
            navigate(redirects);
          } else {
            navigate(routesnkt.home);
          }
        } else {
          dispatch(signInFailure(t('error-something-went-wrong')));
        }
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      dispatch(signInFailure(error.message));
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="flex justify-center items-center w-full h-screen overflow-hidden">
      <div className="w-full flex justify-center items-center">
        <div className="flex flex-row justify-between w-full items-center">
          <div className="w-1/2 justify-center hidden md:flex lg:flex">
            <Lottie
              animationData={animation}
              loop={false}
              className="w-full "
            />
          </div>
          <div className="w-full md:w-full lg:w-1/2 flex flex-col justify-center ">
            <div className="flex justify-between items-center">
              <div className="text-left p-4 font-poppins text-[#0f2ec4] flex flex-row items-center justify-between w-full lg:w-[70%] h-15">
                <p className="text-4xl">{t('sign-in-0')} </p>
                <Button
                  type="text"
                  onClick={() => navigate(routesnkt.home)}
                  icon={<CloseOutlined className="text-black" />}
                />
              </div>
            </div>

            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              initialValues={{ remember: true }}
              onFinish={handleSubmit}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="vertical"
              className="flex flex-col w-full px-4"
            >
              <Form.Item
                label={t('username-2')}
                name="username"
                rules={[
                  { required: true, message: t('please-input-your-username') },
                  {
                    min: 6,
                    message: t('username-must-be-at-least-6-characters-long'),
                  },
                ]}
              >
                <TextInput
                  placeholder={t('username-2')}
                  style={{ borderColor: '#0f2ec4' }}
                />
              </Form.Item>

              <Form.Item
                label={t('password-2')}
                name="password"
                rules={[
                  { required: true, message: t('please-input-your-password') },
                  {
                    message: t(
                      'password-must-include-uppercase-lowercase-number-and-special-character',
                    ),
                  },
                ]}
                className="mb-0"
              >
                <InputPassword
                  placeholder={t('password-1')}
                  style={{ borderColor: '#0f2ec4' }}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  htmlType="submit"
                  loading={loading}
                  className="w-full mt-5"
                  style={{ background: '#0f2ec4' }}
                >
                  <RxEnter className="w-6 h-6 text-white" />
                  <p className="font-semibold text-white">{t('sign-in-0')}</p>
                </Button>
              </Form.Item>
              <div className="flex w-full flex-col justify-start items-start">
                <Link
                  to={routesnkt.signUp}
                  style={{ display: 'flex' }}
                  className="flex gap-1"
                >
                  <Typography
                    className="justify-center py-2 text-center flex gap-1"
                    isRequired={false}
                    value={t('dont-have-an-account')}
                  />

                  <button className="font-medium text-[#0f2ec4] hover:underline">
                    {t('sign-up-0')}
                  </button>
                </Link>
                <p
                  className="text-center text-blue-900 font-poppins hover:underline text-xs cursor-pointer "
                  onClick={() => navigate('/forgot-password')}
                >
                  {t('forgot-password-click-here')}
                </p>
              </div>
            </Form>
          </div>
        </div>
      </div>

      {errorMessage && (
        <Message className="mt-5" type="error" successMessage={errorMessage} />
      )}
      {successMessage && (
        <Message successMessage={successMessage} type="success" />
      )}
    </div>
  );
}
