import { FC,CSSProperties, ChangeEventHandler } from 'react';
import { Input } from 'antd';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

interface CommonAntdPasswordInputProps {
  type?: string;
  placeholder?: string;
  id?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  autoComplete?: string;
  size?: 'large' | 'middle' | 'small';
  style?: CSSProperties;
}

export const CommonAntdPasswordInput: FC<CommonAntdPasswordInputProps> = ({
  type,
  placeholder,
  id,
  size = 'large',
  onChange,
  autoComplete,
  style,
}) => {
  return (
    <Input.Password
      type={type}
      placeholder={placeholder}
      id={id}
      iconRender={(visible) => (visible ? <FaEye /> : <FaEyeSlash />)}
      onChange={onChange}
      autoComplete={autoComplete}
      size={size}
      style={style}
    />
  );
};
