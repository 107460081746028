const PATHS = Object.freeze({
  AUTH: {
    SIGNUP: '/api/v1/auth/sign-up',
    SIGNIN: '/api/v1/auth/sign-in',
    FORGOTPASSWORD: '/api/v1/auth/forgot-password',
    CHANGEPASSWORD: '/api/v1/auth/change-password',
    CONFIRMPASSWORD: '/api/v1/auth/confirm-password',
    RESENDCODE: '/api/v1/auth/resend-confirm-code',
    VERIFYEMAIL: '/api/v1/auth/verify-email',
  },

  ADS: {
    GET: '/api/v1/get-ads',
    LIST: '/api/v1/list-ads',
    MYLIST: '/api/v1/get-ads',
    TEXTPOST: '/api/v1/add-ads',
    IMAGE: '/api/v1/file-upload',
    UPDATE: '/api/v1/update-ads',
    DELETE: '/api/v1/delete-ads',
  },
  CATEGORY: {
    ADD: '/api/v1/admin/add-category',
    GET: '/api/v1/get-category',
    UPDATE: '/api/v1/admin/update-category',
  },
  USER: {
    ADD: 'api/v1/add-users',
    UPDATE: '/api/v1/update-users',
    VERIFYPASSWORD: '/api/v1/verify-user',
    DELETE: '/api/v1/delete-user',
  },
  CRON: {
    MANAGE: '/api/v1/admin/manage-cron',
  },
});
export default PATHS;
