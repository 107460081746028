import {
  Table,
  //   Tag,
  //   Button,
  Popconfirm,
  Message,
} from '../utils/antd-components/index.tsx';
import { useEffect, useState } from 'react';
import { ApiUtils } from '../utils/api.jsx';
import { useTranslation } from 'react-i18next';
//import UserSidebar from '../layouts/UserSidebar';
import PATHS from '../utils/constants/Paths.tsx';
import METHOD_TYPES from '../utils/constants/MethodTypes.tsx';
import Header from '../layouts/Header.jsx';
import Footer from '../layouts/Footer.jsx';
import loadingIcon from '../assets/image/mic_gif.gif';
// import logoIcon from '../assets/image/Adrushes-logo.svg';
import { sendPageView } from '../utils/analytics';
// import routesnkt from '../routes/routes.json';
// import { useNavigate } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { HomeOutlined, InboxOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { FaRegStopCircle } from 'react-icons/fa';
import { MdNotStarted } from 'react-icons/md';
import { formatDateTime } from '../utils/re-usable/populatedHrFormat.tsx';

export default function UserAds() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [cronJobData, setCronJobData] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  useEffect(() => {
    sendPageView(window.location.pathname, 'Cron Jobs');
    fetchCronJobList();
  }, [location.search]);

  const fetchCronJobList = async () => {
    try {
      setIsLoading(true);
      let URL = `${PATHS.CRON.MANAGE}`;

      const data = await ApiUtils(
        URL + '?jobName=checkAdExpiry&action=status',
        '',
        METHOD_TYPES.GET,
        '',
        true,
      );
      if (data.status === 200) {
        setCronJobData(data.response);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error.message);
    }
  };
  const handleStartJob = async (jobName) => {
    try {
      let URL = `${PATHS.CRON.MANAGE}`;

      const data = await ApiUtils(
        URL + `?jobName=${jobName}&action=start`,
        '',
        METHOD_TYPES.POST,
        '',
        true,
      );
      if (data.status === 200) {
        fetchCronJobList();
        setSuccessMessage(data.message);
      } else {
        console.log('error in del CronJobList=====', data);
        setSuccessMessage(null);
        setErrorMessage(data.message);
      }
    } catch (error) {
      console.log('error in del CronJobList1=====', error);
      setSuccessMessage(null);
      setErrorMessage(t('something-went-wrong'));
    }
  };

  const handleStopJob = async (jobName) => {
    try {
      let URL = `${PATHS.CRON.MANAGE}`;

      const data = await ApiUtils(
        URL + `?jobName=${jobName}&action=stop`,
        '',
        METHOD_TYPES.POST,
        '',
        true,
      );
      if (data.status === 200) {
        fetchCronJobList();
        setSuccessMessage(data.message);
      } else {
        console.log('error in del CronjobList=====', data);
        setSuccessMessage(null);
        setErrorMessage(data.message);
      }
    } catch (error) {
      console.log('error in del CronjobList1=====', error);
      setSuccessMessage(null);
      setErrorMessage(t('something-went-wrong'));
    }
  };

  const columns = [
    {
      title: t('jobname'),
      dataIndex: 'jobName',
      key: 'jobName',
    },
    {
      title: t('jobStartedOn'),
      dataIndex: 'startTime',
      key: 'startTime',
      render: (text) => (text ? formatDateTime(text) : '---'),
    },
    {
      title: t('jobStopedOn'),
      dataIndex: 'endTime',
      key: 'endTime',
      render: (text) => (text ? formatDateTime(text) : '---'),
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: t('Change status'),
      dataIndex: 'status',
      key: 'status',
      render: (text, record) =>
        text === 1 ? (
          <Popconfirm
            title={t('stop_job')}
            onConfirm={() => handleStopJob(record.jobName)}
            okText={t('yes-im-sure')}
            cancelText={t('no-cancel')}
          >
            <span className="cursor-pointer">
              <FaRegStopCircle size={20} color="red" />
            </span>
          </Popconfirm>
        ) : text === 2 ? (
          <Popconfirm
            title={t('start_job')}
            onConfirm={() => handleStartJob(record.jobName)}
            okText={t('yes-im-sure')}
            cancelText={t('no-cancel')}
          >
            <span className="cursor-pointer">
              <MdNotStarted size={20} color="red" />
            </span>
          </Popconfirm>
        ) : (
          <Popconfirm
            title={t('are-you-delete-this')}
            onConfirm={() => console.log('delete')}
            okText={t('yes-im-sure')}
            cancelText={t('no-cancel')}
          >
            <span className="cursor-pointer">
              <MdNotStarted size={20} color="red" />
            </span>
          </Popconfirm>
        ),
    },
  ];

  return (
    <div className="bg-[#c9edfaa7] flex flex-col min-h-screen ">
      <Header />
      <div className="ml-3 mt-5">
        {' '}
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">
              <HomeOutlined className="text-[#0F2EC4]" />
              <span className="ml-1">{t('home')}</span>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {' '}
            <InboxOutlined className="text-[#0F2EC4]" />
            <span className="ml-1">{t('cronJobs')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="flex flex-grow w-full h-[89%] p-3 lg:p-8 gap-8">
        <>
          <div className="lg:w-full h-full pt-4 table-auto overflow-y-scroll lg:overflow-x-hidden md:mx-auto scrollbar scrollbar-track-slate-100 scrollbar-thumb-slate-300 dark:scrollbar-track-slate-700 dark:scrollbar-thumb-slate-500">
            {cronJobData ? (
              <Table
                dataSource={cronJobData}
                columns={columns}
                className=" py-4  w-[1000px] lg:w-full lg:max-w-full max-w-[1000px]  "
              />
            ) : (
              <div>Loading...</div>
            )}
          </div>
        </>
      </div>
      {isLoading && (
        <div className="absolute w-screen h-screen flex justify-center items-center inset-0 bg-white bg-opacity-50">
          <img
            src={loadingIcon}
            alt="Loading..."
            className="h-[120px] w-[120px]"
          />
        </div>
      )}
      <Footer />
      {errorMessage && <Message successMessage={errorMessage} type="error" />}
      {successMessage && (
        <Message successMessage={successMessage} type="success" />
      )}
    </div>
  );
}
