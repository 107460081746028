import {
  Image,
  Popconfirm,
  Table,
  Tag,
  Button,
} from '../utils/antd-components';
import { useEffect, useState } from 'react';
import { ApiUtils } from '../utils/api.jsx';
import { useTranslation } from 'react-i18next';
//import UserSidebar from '../layouts/UserSidebar';
import PATHS from '../utils/constants/Paths.tsx';
import METHOD_TYPES from '../utils/constants/MethodTypes.tsx';
import Header from '../layouts/Header.jsx';
import DOMPurify from 'dompurify';
import Footer from '../layouts/Footer.jsx';
import '../tailwind.css';
import { MdDeleteOutline } from 'react-icons/md';
import loadingIcon from '../assets/image/mic_gif.gif';
import logoIcon from '../assets/image/Adrushes-logo.svg';
import { MdOutlineDisabledByDefault } from 'react-icons/md';
// import { sendPageView } from '../utils/analytics';
import dayjs from 'dayjs';
import { Tooltip } from '../utils/antd-components/index.tsx';
import { Breadcrumb } from 'antd';
import { HomeOutlined, InboxOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

export default function AdminAdsPage() {
  const { t } = useTranslation();

  const [usersList, setUsersList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [expandedRows, setExpandedRows] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [count, setCount] = useState(0);

  useEffect(() => {
    fetchUsersAds();
  }, [currentPage]);

  const fetchUsersAds = async () => {
    try {
      setIsLoading(true);
      const URL = `${PATHS.ADS.GET}?page=${currentPage}&limit=${pageSize}`;
      const data = await ApiUtils(URL, '', METHOD_TYPES.GET);
      if (data.status === 200) {
        setIsLoading(false);
        setUsersList(data.data.ads);
        setCount(data.data.count);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error.message);
    }
  };
  const handleMarkAsApproved = async (id) => {
    try {
      const URL = `${PATHS.ADS.UPDATE}/${id}`;
      const data = await ApiUtils(URL, { status: 1 }, METHOD_TYPES.PUT);
      if (data.status !== 200) {
        console.log('error in update status post=====', data.message);
        return;
      } else {
        fetchUsersAds();
      }
    } catch (error) {
      console.log('error in status update post=====', error.message);
    }
  };
  const handleMarkAsInactive = async (id) => {
    try {
      const URL = `${PATHS.ADS.UPDATE}/${id}`;
      const data = await ApiUtils(URL, { status: 2 }, METHOD_TYPES.PUT);
      if (data.status !== 200) {
        console.log('error in update status post=====', data.message);
        return;
      } else {
        fetchUsersAds();
      }
    } catch (error) {
      console.log('error in status update post=====', error.message);
    }
  };
  const handleDeleteUser = async (id) => {
    try {
      const URL = `${PATHS.ADS.DELETE}/${id}`;
      const data = await ApiUtils(URL, { status: 3 }, METHOD_TYPES.DELETE);
      if (data.status === 200) {
        fetchUsersAds();
      } else {
        console.log(data.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const toggleShowMore = (key) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };
  const handlePageChange = (page) => {
    setCurrentPage(page); // Trigger data fetch on page change
  };

  const renderDescription = (text, record) => {
    const sanitizedText = DOMPurify.sanitize(text);
    const wordLimit = 15;
    const words = sanitizedText.split(' ');

    if (words.length <= wordLimit) {
      return <div dangerouslySetInnerHTML={{ __html: sanitizedText }} />;
    }

    const truncatedText = words.slice(0, wordLimit).join(' ');

    return (
      <div>
        <div
          dangerouslySetInnerHTML={{
            __html: expandedRows[record.key]
              ? sanitizedText
              : truncatedText + '...',
          }}
        />
        <Button
          type="link"
          onClick={() => toggleShowMore(record.key)}
          style={{ padding: 0 }}
        >
          {expandedRows[record.key] ? t('show-less') : t('show-more')}
        </Button>
      </div>
    );
  };
  const uniqueCategories = Object.values(
    usersList.reduce((acc, item) => {
      const { _id, uid, name, status } = item.category;
      if (!acc[_id]) {
        acc[_id] = { _id, uid, name, status };
      }
      return acc;
    }, {}),
  );
  const categorydata = uniqueCategories.map((item) => ({
    text: item.name,
    value: item.uid,
  }));
  console.log('category>>>>>', categorydata);
  const columns = [
    {
      title: t('photo'),
      dataIndex: 'photo',
      key: 'photo',
      filteredValue: null,
      render: (text, record) =>
        record.photo ? (
          <Image width={50} height={50} src={text && text} />
        ) : (
          <img src={logoIcon} alt="The ICON" className="h-12" />
        ),
    },
    {
      title: t('add-id'),
      dataIndex: 'uid',
      key: 'ad_id',
      render: (text) => <Tag active="9"> {text} </Tag>,

      onFilter: (value, record) => {
        return record.uid === value;
      },
    },
    {
      title: t('category'),
      dataIndex: 'category.name', // You can also use the full object
      key: 'category',
      render: (text, record) => (
        <Tag color="blue"> {record?.category?.name} </Tag>
      ),
      filters: categorydata,
      onFilter: (value, record) => {
        return record.category.uid === value;
      },
    },
    {
      title: t('status'),
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => (
        <>
          {status === 2 ? (
            <Popconfirm
              title={t('are-you-sure-you-want-to-approve-this-post')}
              onConfirm={() => handleMarkAsApproved(record._id)}
              okText={t('yes')}
              cancelText={t('no')}
            >
              <a
                href="#"
                className="font-medium text-red-500 hover:underline cursor-pointer"
              >
                {t('mark-as-approved')}
              </a>
            </Popconfirm>
          ) : status === 1 ? (
            <Tag active="1">{t('active')}</Tag>
          ) : status === 2 ? (
            <Tag active="2">{t('inActive')}</Tag>
          ) : (
            <Tag active="3">{t('deleted')}</Tag>
          )}
        </>
      ),
      filters: [
        { text: 'Active', value: 1 },
        { text: 'Inactive', value: 2 },
        { text: 'Deleted', value: 3 },
      ],
      onFilter: (value, record) => {
        return record.status === value;
      },
    },
    {
      title: t('created Date'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => dayjs(createdAt).format('DD-MM-YYYY'),
      sorter: (record1, record2) => {
        return record1.createdAt < record2.createdAt;
      },
    },
    {
      title: t('expiry Date'),
      key: 'expiryDate',
      render: (text, record) => {
        const expiryDate = dayjs(record.createdAt).add(30, 'days');
        return expiryDate.format('DD-MM-YYYY');
      },
      sorter: (record1, record2) => {
        return record1.expiryDate < record2.expiryDate;
      },
    },
    {
      title: t('title-0'),
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => (record.photo ? '-------' : text || null),
    },
    {
      title: t('description'),
      dataIndex: 'descp',
      key: 'descp',
      render: (text, record) =>
        record.photo ? '-------' : renderDescription(text, record),
      className: 'description-column',
    },
    {
      title: t('delete-ads-0'),
      key: 'delete',
      render: (text, record) => (
        <div style={{ display: 'flex', gap: '10px' }}>
          {record.status === 1 && (
            <Popconfirm
              title={t('are-you-want-to-inactive')}
              onConfirm={() => handleMarkAsInactive(record._id)}
              okText={t('yes-im-sure')}
              cancelText={t('no-cancel')}
            >
              <span className="cursor-pointer">
                <Tooltip title="InActive">
                  <MdOutlineDisabledByDefault size={20} color="blue" />
                </Tooltip>
              </span>
            </Popconfirm>
          )}
          {record.status !== 3 && (
            <Popconfirm
              title={t('are-you-delete-this')}
              onConfirm={() => handleDeleteUser(record._id)}
              okText={t('yes-im-sure')}
              cancelText={t('No-cancel')}
            >
              <span className="cursor-pointer">
                <Tooltip title="Delete">
                  <MdDeleteOutline size={20} color="red" />
                </Tooltip>
              </span>
            </Popconfirm>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className=" bg-[#c9edfaa7] flex flex-col min-h-screen w-screen">
      <Header />
      <div className="ml-3 mt-5">
        {' '}
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">
              <HomeOutlined className="text-[#0F2EC4]" />
              <span className="ml-1">{t('home')}</span>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {' '}
            <InboxOutlined className="text-[#0F2EC4]" />
            <span className="ml-1">{t('all Ads')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="flex flex-grow w-screen bg-[#f5f5f5f] h-[89%] p-3 lg:p-8 gap-8">
        {/* <UserSidebar /> */}
        <div className="lg:w-full table-auto overflow-x-scroll md:mx-auto  scrollbar scrollbar-track-slate-100 scrollbar-thumb-slate-300 dark:scrollbar-track-slate-700 dark:scrollbar-thumb-slate-500">
          <div className="pt-4">
            {t('total advertise count')}
            <span className="font-bold text-black dark:text-yellow-400">
              {' '}
              {count}
            </span>
          </div>
          <Table
            dataSource={usersList.map((item, index) => ({
              ...item,
              key: item._id || index,
            }))}
            columns={columns}
            loading={isLoading}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              total: count,
              onChange: handlePageChange,
            }}
          />
        </div>
      </div>
      {isLoading && (
        <div className="absolute w-screen h-screen flex justify-center items-center inset-0 bg-white bg-opacity-50">
          <img
            src={loadingIcon}
            alt="Loading..."
            className="h-[120px] w-[120px]"
          />
        </div>
      )}
      <Footer />
    </div>
  );
}
