import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApiUtils } from '../utils/api';
import METHOD_TYPES from '../utils/constants/MethodTypes';
import CustomHeader from '../layouts/Header';
import Footer from '../layouts/Footer';
import { Breadcrumb } from 'antd';
import { HomeOutlined, InboxOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import PATHS from '../utils/constants/Paths.tsx';
import loadingIcon from '../assets/image/mic_gif.gif';
import { useNavigate } from 'react-router-dom';
import { bookMark } from '../redux/user/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { FaBookmark, FaRegBookmark } from 'react-icons/fa';
import routesnkt from '../routes/routes.json';
import { Tooltip, Button, Popover } from 'antd';
import { ShareAltOutlined } from '@ant-design/icons';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import { FacebookIcon, TwitterIcon, WhatsappIcon } from 'react-share';
import { CopyFilled } from '@ant-design/icons';

const MoreDetailsPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [selectedAd, setSelectedAd] = useState(null);
  const [relatedAds, setRelatedAds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [modalImage, setModalImage] = useState(''); // State for the image to be displayed in the modal
  const [currentPage, setCurrentPage] = useState(1); // State for the current page
  const [isPopoverVisible, setIsPopoverVisible] = useState(false); // To manage the popover state
  const navigate = useNavigate();
  const checkbookmark = useSelector((state) => state.user);
  const booked = checkbookmark?.booked || {};
  const dispatch = useDispatch();

  const toggleBookmark = () => {
    if (selectedAd._id) {
      const currentStatus = booked[selectedAd._id];
      dispatch(bookMark([selectedAd._id, !currentStatus]));
    }
  };

  const handleCardClick = (adId) => {
    if (adId) {
      navigate(`${routesnkt.moreDetailsPages}${adId}`);
    }
  };

  const handleImageClick = (image) => {
    setModalImage(image); // Set the clicked image in the modal
    setIsModalOpen(true); // Open the modal
  };

  const adUrl = `${window.location.origin}${routesnkt.moreDetailsPages}${id}`;
  const shareMenu = (
    <div
      style={{
        display: 'flex', // Flex layout for horizontal alignment
        gap: '16px', // Add spacing between icons
        padding: '10px', // Adjust padding around the menu
        borderRadius: '8px', // Rounded corners for the menu
        backgroundColor: '#f9f9f9', // Background color
      }}
    >
      <div
        onClick={(e) => {
          e.stopPropagation(); // Prevent card click
          setIsPopoverVisible(false); // Close the popover
        }}
        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      >
        <FacebookShareButton url={adUrl}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation(); // Prevent card click
          setIsPopoverVisible(false); // Close the popover
        }}
        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      >
        <TwitterShareButton url={adUrl}>
          <TwitterIcon size={32} round />
        </TwitterShareButton>
      </div>
      <div
        onClick={(e) => {
          e.stopPropagation(); // Prevent card click
          setIsPopoverVisible(false); // Close the popover
        }}
        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      >
        <WhatsappShareButton url={adUrl}>
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
      </div>
      <div
        onClick={(e) => {
          navigator.clipboard.writeText(adUrl); // Copy URL to clipboard
          alert('URL copied to clipboard!');
          e.stopPropagation(); // Prevent card click
          setIsPopoverVisible(false); // Display alert
        }}
        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
      >
        <CopyFilled style={{ fontSize: '30px', color: '#000' }} />
      </div>
    </div>
  );

  useEffect(() => {
    const fetchAdData = async () => {
      try {
        const URL = `${PATHS.ADS.LIST}?_id=${id}`;
        const response = await ApiUtils(URL, '', METHOD_TYPES.LIST);

        if (response.status === 200) {
          const ad = response?.data?.ads[0];
          setSelectedAd(ad);
          setLoading(false);

          // Fetch related ads based on the category
          if (ad?.category?._id) {
            const relatedAdsUrl = `${PATHS.ADS.LIST}?category=${ad.category._id}&status=1`;
            const relatedResponse = await ApiUtils(
              relatedAdsUrl,
              '',
              METHOD_TYPES.LIST,
            );
            if (relatedResponse.status === 200) {
              setRelatedAds(relatedResponse.data.ads);
            }
          }
        } else {
          throw new Error('Failed to fetch ad data');
        }
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchAdData();
  }, [id]);

  // Get the related ads to display based on pagination

  const itemsPerPage = 5;

  // Handle pagination change
  const totalPages = Math.ceil(relatedAds.length / itemsPerPage);
  const currentAds = relatedAds.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  if (loading) {
    return (
      <div className="absolute w-screen h-screen flex justify-center items-center inset-0 bg-white bg-opacity-50">
        <img
          src={loadingIcon}
          alt="loading..."
          className="h-[200px] w-[200px]"
        />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="w-full flex flex-col bg-[#c9edfaa7] min-h-screen">
      <div className="z-20 p-0 sticky top-0 bg-[#ffffff]">
        <CustomHeader />
      </div>
      <div className="pt-4 px-5">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">
              <HomeOutlined className="text-[#0F2EC4]" />
              <span className="ml-1">{t('home')}</span>
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <InboxOutlined className="text-[#0F2EC4]" />
            <span className="ml-1">{t('ads')}</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="h-full flex-grow pt-6 px-5">
        <div className="bg-white p-4 rounded-lg shadow-lg mb-8">
          <div>
            <h2 className="bg-gradient-to-r from-white via-gray-100 to-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 mb-8 text-center font-bold text-lg">
              {selectedAd?.title || selectedAd?.category?.name || 'Untitled'}
            </h2>
          </div>
          {selectedAd?.photo && (
            <div className="mb-6">
              <img
                src={selectedAd?.photo}
                alt="Ad Image"
                className="w-full sm:w-fit max-w-full sm:max-w-[600px] h-auto max-h-[300px] sm:max-h-[400px] rounded-lg object-cover mx-auto"
                onClick={() => handleImageClick(selectedAd?.photo)} // Open modal on image click
              />
            </div>
          )}
          <div
            className="text-lg text-gray-900 mb-6"
            dangerouslySetInnerHTML={{ __html: selectedAd?.descp }}
          />
          <div className="flex flex-col md:flex-row gap-4 p-4 bg-white backdrop-blur-md rounded-lg shadow-xl border border-white/20">
            {/* Creator Information */}
            <p className="text-lg text-gray-900 flex items-center space-x-2">
              <strong className="font-bold text-blue-950">
                {t('creator')}:
              </strong>
              <span className="ml-2 text-gray-900">
                {selectedAd.user?.name || 'N/A'}
              </span>
            </p>

            {/* Category Information */}
            <p className="text-lg text-gray-900 flex items-center space-x-2">
              <strong className="font-bold text-blue-950">
                {t('category')}:
              </strong>
              <span className="ml-2 text-gray-900">
                {selectedAd.category?.name || 'N/A'}
              </span>
            </p>

            {/* Created Date */}
            <p className="text-lg text-gray-900 flex items-center space-x-2">
              <strong className="font-bold text-blue-950">
                {t('created Date')}:
              </strong>
              <span className="ml-2 text-gray-900">
                {selectedAd.createdAt
                  ? new Intl.DateTimeFormat('en-US', {
                      day: '2-digit',
                      month: 'short',
                      year: 'numeric',
                    }).format(new Date(selectedAd.createdAt))
                  : 'N/A'}
              </span>
            </p>

            {/* Share Button and Bookmark Icon in same row */}
            <div className="flex items-center gap-4 mt-1 ">
              {/* Share Button with Popover */}
              <div className="cursor-pointer">
                <Tooltip title="Share Post">
                  <Popover
                    content={shareMenu}
                    trigger="click"
                    placement="bottom"
                    visible={isPopoverVisible}
                    onVisibleChange={setIsPopoverVisible}
                    className="flex flex-row"
                  >
                    <Button
                      icon={<ShareAltOutlined />}
                      shape="circle"
                      size="large"
                      className="bg-white text-blue-900 hover:bg-blue-300 shadow-lg"
                      onClick={(e) => e.stopPropagation()}
                    />
                  </Popover>
                </Tooltip>
              </div>

              {/* Bookmark Icon */}
              <div
                className="cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleBookmark();
                }}
              >
                {booked[selectedAd._id] === true ? (
                  <FaBookmark style={{ fontSize: 22 }} color="#4A90E2" />
                ) : (
                  <FaRegBookmark style={{ fontSize: 22 }} color="#4A90E2" />
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Related Ads Section */}
        {relatedAds.filter((ad) => ad._id !== id).length > 0 && (
          <div className="bg-gradient-to-br from-[#c9edfaa7] to-[#e2f7fb] flex flex-col p-6 rounded-lg mb-10 shadow-lg">
            <h3 className="text-3xl font-semibold text-gray-900 mb-6 text-left border-b-2 pb-2 border-blue-500">
              {t('related-ads')}
            </h3>

            <div className="flex flex-wrap gap-6 justify-center items-start">
              {currentAds
                .filter((ad) => ad._id !== id) // Exclude the main ad from the related ads
                .map((ad) => (
                  <div
                    key={ad._id}
                    className="min-w-[250px] w-full sm:w-[250px] md:w-[300px] lg:w-[250px] h-80 bg-white hover:scale-105 hover:shadow-md border p-5 rounded-lg shadow-sm cursor-pointer transition-transform duration-300 ease-in-out transform-gpu"
                    onClick={() => handleCardClick(ad._id)} // Trigger handleCardClick on card click
                  >
                    <h4 className="text-lg font-semibold text-blue-800 mb-2 truncate">
                      {ad.title}
                    </h4>
                    {ad.photo ? (
                      <img
                        src={ad.photo}
                        alt="Related Ad"
                        className="w-full h-48 object-cover rounded-md mb-3"
                      />
                    ) : null}
                    <p className="text-gray-700 mt-2 relative w-full h-52 flex justify-start items-start overflow-hidden text-ellipsis max-h-56">
                      {ad.descp ? ad.descp.replace(/<[^>]+>/g, '') : ''}
                    </p>
                  </div>
                ))}
            </div>

            {/* Pagination Controls */}
            <div className="flex justify-center sm:justify-end mt-6 space-x-3">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="px-3 py-2 bg-white shadow-md hover:shadow-lg text-blue-900 rounded-full hover:bg-blue-500 disabled:bg-gray-200 transition duration-300 flex items-center justify-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                >
                  <path d="M15 19l-7-7 7-7" />
                </svg>
              </button>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="px-3 py-2 shadow-md hover:shadow-lg bg-white text-blue-900 rounded-full hover:bg-blue-500 disabled:bg-gray-200 transition duration-300 flex items-center justify-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                >
                  <path d="M9 5l7 7-7 7" />
                </svg>
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Modal for Image Preview */}
      {isModalOpen && (
        <div
          className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 flex justify-center items-center"
          onClick={() => setIsModalOpen(false)} // Close modal when clicked outside image
        >
          <img
            src={modalImage}
            alt="Image Preview"
            className="max-w-[100%] max-h-[100%] rounded-lg shadow-lg"
            onClick={(e) => e.stopPropagation()} // Prevent closing the modal when image is clicked
          />
        </div>
      )}

      <Footer />
    </div>
  );
};

export default MoreDetailsPage;
