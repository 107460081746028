import { useEffect, useState } from 'react';
import Header from '../layouts/Header';
import { Form } from 'antd';
import { Card, TextInput, Button } from '../utils/antd-components';
import { EditOutlined } from '@ant-design/icons';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { ApiUtils } from '../utils/api';
import PATHS from '../utils/constants/Paths';
import METHOD_TYPES from '../utils/constants/MethodTypes';
import { Message } from '../utils/antd-components';
import { useTranslation } from 'react-i18next';
import Footer from '../layouts/Footer';
import { IoClose } from 'react-icons/io5';
// import loadingIcon from '../assets/image/mic_gif.gif';
import { sendPageView } from '../utils/analytics';
import { useNavigate } from 'react-router-dom';
import routesnkt from '../routes/routes.json';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Breadcrumb } from 'antd';
import { HomeOutlined, UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const UserProfile = () => {
  const authUser = useAuthUser();
  const userDetails = authUser.userDetails;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [publishError, setPublishError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [, setIsLoading] = useState(false);

  useEffect(() => {
    sendPageView(window.location.pathname, 'User Profile');
  }, []);

  const onFinish = async (e) => {
    setIsLoading(true);
    try {
      const payload = { ...e };
      const URL = `${PATHS.USER.UPDATE}/${userDetails?._id}`;
      const data = await ApiUtils(URL, payload, METHOD_TYPES.PUT);
      if (data.status !== 200) {
        setPublishError(data.message);
        setIsLoading(false);
        return;
      }

      if (data.status === 200) {
        setSuccessMessage(data.response.message);
        setPublishError(null);
        setIsLoading(false);
      }
    } catch (error) {
      setPublishError(t('something-went-wrong-0'));
      setIsLoading(false);
    }
    setIsEditing(false);
  };

  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  return (
    <div className="w-full h-screen bg-[#c9edfaa7]">
      <Header />
      <div className="flex flex-col justify-center items-center w-full h-[85%] p-3 gap-8">
        {/* Breadcrumbs for desktop view */}
        <div className="hidden lg:flex lg:pl-[3%] lg:pt-1 w-full">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/">
                <HomeOutlined className="text-[#0F2EC4]" />
                <span className="ml-1">{t('home')}</span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <UserOutlined className="text-[#0F2EC4]" />
              <span className="ml-1">{t('profile-1')}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>

        {/* Profile Card */}
        <Card
          className="flex justify-center shadow-lg   sm:px-2 sm:py-2     md:px- md:py-7  lg:px-8 lg:py-1   xl:px-10 xl:py-5  w-full  lg:w-3/5 rounded-xl"
          bodyStyle={{ width: '100%' }}
        >
          <div className="flex justify-between items-center w-full">
            {/* Back button for mobile view */}
            <div className="block lg:hidden">
              <Button
                className="bg-[#ffffff] text-[#0F2EC4] font-extrabold"
                onClick={() => navigate(routesnkt.home)}
                icon={<ArrowLeftOutlined />}
              />
            </div>

            <h2 className="text-[#0f2ec4] text-2xl font-semibold">
              {t('your-profile-info')}
            </h2>

            <Button
              type="link"
              icon={!isEditing ? <EditOutlined /> : <IoClose />}
              className={!isEditing ? 'text-[#000000]' : 'text-[#FF0000]'}
              style={{ fontSize: '16px', padding: 0 }}
              onClick={toggleEditMode}
            >
              {!isEditing ? t('edit-1') : t('cancel')}
            </Button>
          </div>

          {/* Form */}
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={{
              name: userDetails?.name || '',
              organization: userDetails?.organization || '',
              email: userDetails?.email || '',
              ph_no: userDetails?.ph_no || '',
            }}
            className="flex flex-col gap-4 py-4 lg:gap-10"
            disabled={!isEditing}
          >
            {/* Form Items */}
            <div className="flex flex-col lg:flex-row gap-2">
              <Form.Item
                name="name"
                label={t('Name')}
                rules={[
                  { required: true, message: t('please-enter-your-name') },
                  {
                    pattern: /^[a-zA-Z\s]*$/,
                    message: t('name-cannot-contain-numbers'),
                  },
                  {
                    min: 6,
                    message: t('username-must-be-at-least-6-characters-long'),
                  },
                ]}
                className="lg:w-1/3"
              >
                <TextInput
                  placeholder={t('Name')}
                  disabled={!isEditing}
                  style={{ borderColor: '#0f2ec4' }}
                />
              </Form.Item>

              <Form.Item
                name="email"
                label={t('email')}
                disabled
                className="lg:w-1/3"
              >
                <TextInput
                  disabled
                  placeholder={t('email')}
                  style={{ borderColor: '#0f2ec4' }}
                />
              </Form.Item>

              <Form.Item
                name="organization"
                label={t('organization')}
                className="lg:w-1/3"
              >
                <TextInput
                  placeholder={t('organization')}
                  disabled={!isEditing}
                  style={{ borderColor: '#0f2ec4' }}
                />
              </Form.Item>
            </div>

            <div className="flex flex-col lg:flex-row justify-between gap-4 w-full">
              <Form.Item
                name="ph_no"
                label={t('phone-number')}
                rules={[
                  {
                    pattern: /^[0-9]{10}$/,
                    message: t('please-enter-a-valid-phone-number'),
                  },
                ]}
                className="lg:w-1/3"
              >
                <TextInput
                  placeholder={t('phone-number')}
                  disabled={!isEditing}
                  style={{ borderColor: '#0f2ec4' }}
                  className="w-full"
                />
              </Form.Item>
            </div>

            {/* Submit Button for Editing */}
            {isEditing && (
              <div className="flex justify-center mt-4 p4">
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    background:
                      'linear-gradient(90deg, #0f2ec4 0%, #0f2ec4 100%)',
                    borderRadius: '25px',
                    width: '150px',
                  }}
                >
                  {t('save')}
                </Button>
              </div>
            )}
          </Form>
        </Card>
      </div>

      {/* Error and Success Messages */}
      {publishError && <Message publishError={publishError} type="error" />}
      {successMessage && (
        <Message successMessage={successMessage} type="success" />
      )}

      {/* Footer with reduced padding */}
      <Footer className="py-2" />
    </div>
  );
};

export default UserProfile;
