import { useEffect } from 'react';
import { Row, Col, Breadcrumb } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import CustomHeader from '../layouts/Header';
import Footer from '../layouts/Footer';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Termsandcond = () => {
  const { t } = useTranslation();
  // Control body overflow (for future use if needed)
  useEffect(() => {
    document.body.style.overflow = 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <div className="w-full flex flex-col bg-[#c9edfaa7] min-h-screen">
      <div className="z-20 p-0 sticky top-0 bg-[#ffffff]">
        <CustomHeader />
      </div>

      {/* Removed pt-10 to eliminate gap */}
      <div className="flex-grow px-0 transition-opacity duration-300">
        {' '}
        {/* Adjusted padding */}
        <Row
          gutter={[0, 0]} // Set gutter to 0 for full width
          justify="start"
          className="h-full flex justify-center items-center" // Add h-full to Row
        >
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={24}
            xxl={24}
            className="flex justify-center items-center"
          >
            <div className="bg-white p-6  shadow-lg w-full h-full">
              {' '}
              {/* Set w-full and h-full */}
              <div className="ml-0">
                {' '}
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link to="/">
                      <HomeOutlined className="text-[#0F2EC4]" />
                      <span className="ml-1">{t('home')}</span>
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>{t('terms and conditions')}</Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="w-2/3 mx-auto">
                {' '}
                {/* Ensure inner div is also full width */}
                <h2 className="text-2xl font-bold mb-2 p-2">
                  Terms and Conditions
                </h2>
                <p className="mb-4">{t('tcp1')}</p>
                <p className="mb-4">{t('tcp2')}</p>
                <h2 className="text-xl font-semibold mb-2">Content Usage</h2>
                <p className="mb-4">
                  Our website&apos;s material is meant to provide general
                  information. We endeavour to offer accurate and relevant
                  information; however, we retain the right to change or update
                  content at any time and without notice. Users are recommended
                  to check any information before relying on it, as the material
                  provided may not be complete. The material should not be taken
                  as professional advice, and we recommend using it with
                  caution. You are solely responsible for making decisions based
                  on this information.
                </p>
                <h2 className="text-xl font-semibold mb-2">Cookies</h2>
                <p className="mb-4">
                  Adrushes uses cookies to improve the user experience and
                  monitor page traffic. By using our website, you agree to the
                  usage of cookies. Cookies are little data files that are kept
                  on your device and help us improve the functionality of our
                  websites and your browsing experience. They may record your
                  preferences, login information, and other details of your
                  connection with our website.
                </p>
                <h2 className="text-xl font-semibold mb-2">
                  Disclaimer of Guarantees
                </h2>
                <p className="mb-4">
                  We make no warranty on the completeness, accuracy, or
                  usefulness of any information on our website. While we aim to
                  provide trustworthy content, we cannot be held responsible for
                  any mistakes or errors in the information supplied. Users are
                  encouraged to perform their research and verify the material
                  before making any decisions based on it.
                </p>
                <h2 className="text-xl font-semibold mb-2">
                  User Responsibility
                </h2>
                <p className="mb-4">
                  You must ensure that any items, services, or information
                  obtained through our website satisfy your unique requirements.
                  We are not accountable for any damages or losses that result
                  from your use of the site or its content. Every user has
                  different needs, and what works for one person may not work
                  for another.
                </p>
                <h2 className="text-xl font-semibold mb-2">
                  Intellectual Property
                </h2>
                <p className="mb-4">
                  The materials on Adrushes, including design, layout, and
                  graphics, are either owned by us or licensed to us.
                  Reproduction of this material without proper authorization is
                  prohibited and may infringe copyright laws. We take
                  intellectual property rights seriously and are committed to
                  protecting our content from unauthorized use.
                </p>
                <h2 className="text-xl font-semibold mb-2">
                  Third-Party Links
                </h2>
                <p className="mb-4">
                  Our website may contain links to other websites for your
                  convenience. These links are provided to enhance your
                  experience and offer additional resources. However, we do not
                  endorse these external sites and are not responsible for their
                  content or accuracy. Visiting these links is at your own risk.
                </p>
                <h2 className="text-xl font-semibold mb-2">
                  Limitation of Liability
                </h2>
                <p className="mb-4">
                  Adrushes shall not be liable for any direct, indirect,
                  incidental, or consequential damages arising from your use of
                  our site. Your use of the website is at your own risk, and you
                  assume all responsibilities associated with it.
                </p>
                <h2 className="text-xl font-semibold mb-2">Indemnification</h2>
                <p className="mb-4">
                  You agree to indemnify and hold harmless Adrushes, its
                  affiliates, and employees from any claims, losses,
                  liabilities, or expenses arising from your use of the site or
                  your violation of these terms.
                </p>
                <h2 className="text-xl font-semibold mb-2">Changes in Terms</h2>
                <p className="mb-4">
                  We retain the right to amend these terms and conditions at any
                  time. The continued use of the site following revisions shows
                  acceptance of the new conditions.
                </p>
                <h2 className="text-xl font-semibold mb-2">Governing Law</h2>
                <p className="mb-4">
                  These terms are governed by the laws of India. Any disputes
                  arising from these terms will be subject to the exclusive
                  jurisdiction of the courts located in Puducherry.
                </p>
                <h2 className="text-xl font-semibold mb-2">
                  Contact Information
                </h2>
                <p className="mb-4">
                  For any questions or concerns regarding these terms and
                  conditions, please contact us at:
                </p>
                <p className="mb-4">Address: Puducherry</p>
                <p className="mb-4">Phone: +91 413-2233944</p>
                <p className="mb-4">
                  Email:{' '}
                  <a
                    href="mailto:info@netkathri.com"
                    className="text-blue-600 hover:underline"
                  >
                    info@netkathri.com
                  </a>
                </p>
                <p className="mt-4">
                  Thank you for visiting Adrushes! We appreciate your
                  understanding and cooperation. Your engagement with our site
                  is valuable to us, and we are committed to providing you with
                  a reliable and informative experience.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <Footer />
    </div>
  );
};

export default Termsandcond;
