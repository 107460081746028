import { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  Drawer,
  Menu,
  Select,
  Dropdown,
  Badge,
  Input,
  Form,
  message,
} from 'antd';
import {
  MenuOutlined,
  PlusOutlined,
  UserOutlined,
  InboxOutlined,
  PoweroffOutlined,
  LockOutlined,
  DeleteOutlined,
  DatabaseOutlined,
  CloseOutlined,
  SettingOutlined,
  SubnodeOutlined,
} from '@ant-design/icons';
import { FaBookmark } from 'react-icons/fa6';
import logoIcon from '../assets/image/Adrushes-logo.svg';
import { GoPerson } from 'react-icons/go';
import { useDispatch } from 'react-redux';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { signoutSuccess } from '../redux/user/userSlice.js';
import routesnkt from '../routes/routes.json';
import { Link, useNavigate } from 'react-router-dom';
import { useLanguageContext } from '../utils/context/LanguageContext.tsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PATHS from '../utils/constants/Paths';
import METHOD_TYPES from '../utils/constants/MethodTypes';
import { ApiUtils } from '../utils/api';
import {
  Modal,
  InputPassword,
  Message,
} from '../utils/antd-components/index.tsx';
// import { resetBookmarks } from '../redux/user/userSlice.js';
import { Tooltip } from '../utils/antd-components/index.tsx';
// const { SubMenu } = Menu;

const Header = () => {
  const { Title } = Typography;
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isLoginVisible] = useState(true);
  const [isMobileView, setIsMobileView] = useState(false); // New state for mobile view detection
  const navigate = useNavigate();
  const { t } = useTranslation();
  //const [selectedCategory, setSelectedCategory] = useState([]);
  const { LANGUAGELIST, onClickLanguageChange, i18n } = useLanguageContext();
  const { Option } = Select;
  const authUser = useAuthUser();
  const userDetails = authUser ? authUser.userDetails : null;
  const signOut = useSignOut();
  const dispatch = useDispatch();
  const [categories, setCategories] = useState([]);
  const pinnedCard = useSelector((state) => state.user);
  console.log('header', pinnedCard);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  useEffect(() => {
    setSelectedLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    fetchCategories();
    // dispatch(resetBookmarks());
  }, []);

  const handleLanguageChange = (e) => {
    setSelectedLanguage(e);
    onClickLanguageChange(e);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const fetchCategories = async () => {
    try {
      const URL = PATHS.CATEGORY.GET;
      const response = await ApiUtils(URL, METHOD_TYPES.GET); // Specify GET method
      if (response.status === 200) {
        // Filter categories where status is 1
        const filteredCategories = response.data.filter(
          (category) => category.status === 1,
        );
        setCategories(filteredCategories); // Update state with filtered categories
      } else {
        console.error('Error fetching data:', response);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null); // null, 'delete', or 'password'

  const handleDeleteOpen = () => {
    setModalType('delete');
    setIsModalOpen(true);
  };

  const changePasswordOpen = () => {
    setModalType('password');
    setIsModalOpen(true);
  };

  const handleDeleteClose = () => {
    setIsModalOpen(false);
    setModalType(null);
  };

  const deleteAccount = async () => {
    try {
      const verifyPasswordURL = PATHS.USER.VERIFYPASSWORD;
      const payload = { ...formData };

      const verifyResponse = await ApiUtils(
        verifyPasswordURL,
        payload,
        METHOD_TYPES.POST,
      );

      if (verifyResponse.status === 200) {
        message.success(verifyResponse.message);

        const deleteAccountURL = PATHS.USER.DELETE;
        const deletePayload = { email: formData.email };

        const deleteResponse = await ApiUtils(
          deleteAccountURL,
          deletePayload,
          METHOD_TYPES.DELETE,
        );
        if (deleteResponse.status === 200) {
          message.success(deleteResponse.message);
          handleDeleteClose();
          handleSignout();
        } else {
          message.error(deleteResponse.message);
        }
      } else {
        message.error(verifyResponse.message);
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };

  const changePassword = async () => {
    try {
      const payload = {
        username: userDetails.username,
        oldPassword: form.getFieldValue('oldPassword'),
        newPassword: form.getFieldValue('newPassword'),
      };
      const URL = PATHS.AUTH.CHANGEPASSWORD;
      const response = await ApiUtils(URL, payload, METHOD_TYPES.POST);
      if (response.status === 200) {
        <Message type="success">{response.message}</Message>;
        handleDeleteClose();
      } else {
        <Message type="error">{response.message}</Message>;
      }
    } catch (error) {
      console.log('Error:', error);
    }
  };

  const createSlug = (name) => {
    return name
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/(^-|-$)/g, '');
  };

  const handleMenuClick = (data) => {
    const categorySlug = createSlug(data.name);
    navigate(`${routesnkt.getAds}/${categorySlug}`, {
      state: { _id: data._id, name: data.name },
    });
  };

  const handleSignout = async () => {
    try {
      signOut();
      dispatch(signoutSuccess());
      navigate('/');
    } catch (error) {
      console.log('signouterr=====', error);
    }
  };

  const mainmenuItems = categories.map((category) => ({
    id: category._id,
    key: category._id,
    label: (
      <span
        style={{ color: '#000000' }}
        onClick={() => handleMenuClick(category)}
      >
        {category.name}
      </span>
    ),
  }));

  const loggedOutMenu = [
    {
      key: 'login',
      icon: <UserOutlined className="text-[#0F2EC4]" />,
      label: t('sign-in'),
      onClick: () => {
        navigate(routesnkt.signIn);
      },
    },
    {
      key: 'signup',
      icon: <PlusOutlined className="text-[#0F2EC4]" />,
      label: t('sign-up'),
      onClick: () => {
        navigate(routesnkt.signUp);
      },
    },
  ];

  const menuItems = [
    {
      key: '1',
      icon: <UserOutlined className="text-[#0F2EC4]" />,
      label: t('profile-1'),
      onClick: () => {
        navigate(routesnkt.profile);
      },
    },
    {
      key: '2',
      icon: <InboxOutlined className="text-[#0F2EC4]" />,
      label: t('your-ads'),
      onClick: () => {
        navigate(routesnkt.userAds);
      },
    },

    {
      key: '3',
      icon: <LockOutlined className="text-[#0F2EC4]" />,
      label: t('change password'),
      onClick: changePasswordOpen,
    },
    {
      key: '4',
      icon: <DeleteOutlined className="text-[#0F2EC4]" />,
      label: t('deleteAccount'),
      onClick: handleDeleteOpen,
    },
    {
      key: '5',
      icon: <PoweroffOutlined className="text-[#0F2EC4]" />,
      label: t('log-out'),
      onClick: handleSignout,
    },
    // {
    //   key: '6',
    //   icon: null,
    //   label: (
    //     <SubMenu
    //       title={
    //         LANGUAGELIST[selectedLanguage]?.nativeName || 'Select Language'
    //       }
    //     >
    //       {LANGUAGELIST &&
    //         Object.keys(LANGUAGELIST).map((key) => (
    //           <Menu.Item key={key} onClick={() => handleLanguageChange(key)}>
    //             {LANGUAGELIST[key].nativeName}
    //           </Menu.Item>
    //         ))}
    //     </SubMenu>
    //   ),
    // },
  ];

  const adminMenu = [
    {
      key: '1',
      icon: <UserOutlined className="text-[#0F2EC4]" />,
      label: t('profile-1'),
      onClick: () => {
        navigate(routesnkt.profile);
      },
    },
    {
      key: '2',
      icon: <InboxOutlined className="text-[#0F2EC4]" />,
      label: t('your-ads'),
      onClick: () => {
        navigate(routesnkt.userAds);
      },
    },
    {
      key: '3',
      icon: <DatabaseOutlined className="text-[#0F2EC4]" />,
      label: t('all Ads'),
      onClick: () => {
        navigate(routesnkt.allAds);
      },
    },
    {
      key: '6',
      icon: <SettingOutlined className="text-[#0F2EC4]" />,
      label: t('cronJobs'),
      onClick: () => {
        navigate(routesnkt.cronJobs);
      },
    },
    {
      key: '4',
      icon: <SubnodeOutlined className="text-[#0F2EC4]" />,
      label: t('add Categories'),
      onClick: () => {
        navigate(routesnkt.addCategory);
      },
    },
    {
      key: '5',
      icon: <PoweroffOutlined className="text-[#0F2EC4]" />,
      label: t('log-out'),
      onClick: handleSignout,
    },

    // {
    //   key: '6',
    //   icon: null,
    //   label: (
    //     <SubMenu
    //       title={
    //         LANGUAGELIST[selectedLanguage]?.nativeName || 'Select Language'
    //       }
    //       className="w-full"
    //     >
    //       {LANGUAGELIST &&
    //         Object.keys(LANGUAGELIST).map((key) => (
    //           <Menu.Item key={key} onClick={() => handleLanguageChange(key)}>
    //             {LANGUAGELIST[key].nativeName}
    //           </Menu.Item>
    //         ))}
    //     </SubMenu>
    //   ),
    // },
  ];

  const selectedMenu = userDetails
    ? userDetails.roleId?.name === 'admin'
      ? adminMenu
      : menuItems
    : loggedOutMenu;

  const menu = {
    items: selectedMenu,
  };

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < 768;
      setIsMobileView(isMobile);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Return statement now correctly inside the function
  return (
    <>
      <div className="flex items-center justify-between w-full px-4 py-4 bg-white shadow-md">
        <div className="flex jutify-center items-center flex-row-reverse gap-3 ">
          <Drawer
            title={
              <div className="flex items-center justify-between ">
                <span>{t('categories')}</span>
                {/* Display login from the drawer title in mobile view */}
                {isMobileView && (
                  <Badge
                    color="red"
                    count={
                      pinnedCard?.booked
                        ? Object.values(
                            Object.fromEntries(
                              Object.entries(pinnedCard.booked).filter(
                                ([, value]) => value === true, // Ignore the key, only check the value
                              ),
                            ),
                          ).length
                        : 0 // Default to 0 if pinnedCard.booked is null or undefined
                    }
                  >
                    <Link to={routesnkt.pinpage}>
                      <Button
                        type="primary"
                        icon={<FaBookmark />}
                        className="gap-4 rounded-full text-[#0f2ec4] bg-white"
                      />
                    </Link>
                  </Badge>
                )}
              </div>
            }
            placement="left"
            onClose={toggleMobileMenu}
            open={isMobileMenuOpen}
            className="text-[#0f2ec4] font-poppins text-lg"
            width={400}
            styles={{
              body: {
                background: '#f5f5f5',
                padding: '24px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              },
              header: {
                background: '#0f2ec4',
                color: 'white',
                fontSize: '24px',
                fontWeight: 'bold',
                textAlign: 'center',
              },
            }}
            closeIcon={<CloseOutlined className="text-white" />}
            footer={
              isMobileView && (
                <div className="flex flex-center items-start gap-4 shadow-xl">
                  <Select
                    required={false}
                    onChange={handleLanguageChange}
                    value={selectedLanguage}
                    placeholder={t('select-0')}
                    style={{ width: '100%', fontSize: '14px' }}
                  >
                    {LANGUAGELIST &&
                      Object.keys(LANGUAGELIST).map((key) => (
                        <Option key={key} value={key}>
                          {LANGUAGELIST[key].nativeName}
                        </Option>
                      ))}
                  </Select>

                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    style={{ width: '100%', fontSize: '14px' }}
                    onClick={() => navigate(routesnkt.postads)}
                    className="bg-[#ffffff] text-[#0F2EC4] font-bold shadow-lg hover:shadow-xl transition duration-300 p-2 rounded"
                  >
                    {t('post-ads')}
                  </Button>
                </div>
              )
            }
          >
            <Menu
              mode="vertical"
              onClick={(e) => {
                const selectedCategory = categories.find(
                  (category) => category._id === e.key,
                );
                handleMenuClick(selectedCategory);
                toggleMobileMenu();
              }}
              className="border-none w-full bg-[#f5f5f5] shadow-lg"
              style={{ border: 'none' }}
              items={mainmenuItems}
            />
          </Drawer>

          <div
            className="flex items-center gap-3 cursor-pointer"
            onClick={() => navigate('/')}
          >
            <img src={logoIcon} alt="The ICON" className="h-10 flex" />
            <Title
              level={5}
              className="font-poppins hidden md:flex lg:flex mt-3"
              style={{ color: '#0f2ec4', fontSize: '20px' }}
            >
              <Tooltip title="AdRushes">{t('adrushes')}</Tooltip>
            </Title>
          </div>

          <Button
            type="text"
            icon={<MenuOutlined className="text-[#0f2ec4]  !text-2xl" />}
            onClick={toggleMobileMenu}
            className="ml-2"
          />
        </div>
        <div>
          {isMobileView && (
            <div className="flex flex-row gap-1">
              <Tooltip title={'PostAds'}>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  style={{
                    padding: '5px 10px',
                    fontSize: '12px',
                    height: '32px',
                  }}
                  className="flex justify-right items-center gap-10 rounded-full bg-[#ffffff] text-[#0F2EC4] font-bold shadow-lg hover:shadow-xl transition duration-300 p-2 "
                  onClick={() => navigate(routesnkt.postads)}
                ></Button>
              </Tooltip>
              <Dropdown
                menu={menu}
                trigger={['click']}
                placement="bottomRight"
                overlayStyle={{ marginRight: 10 }}
              >
                <Button
                  type="default"
                  shape="circle"
                  icon={<GoPerson className="text-[#0f2ec4] !text-2xl" />}
                  className="cursor-pointer flex items-end gap-3"
                />
              </Dropdown>
            </div>
          )}
        </div>

        {!isMobileView && (
          <div className="flex items-center gap-3">
            <Select
              required={false}
              onChange={handleLanguageChange}
              value={selectedLanguage}
              placeholder={t('select-0')}
              style={{ width: '100px', fontSize: '14px' }}
            >
              {LANGUAGELIST &&
                Object.keys(LANGUAGELIST).map((key) => (
                  <Option key={key} value={key}>
                    {LANGUAGELIST[key].nativeName}
                  </Option>
                ))}
            </Select>

            <Button
              type="primary"
              icon={<PlusOutlined />}
              style={{
                padding: '5px 10px',
                fontSize: '14px',
                height: '32px',
              }}
              className="flex items-center gap-2 rounded-full bg-[#ffffff] text-[#0F2EC4] font-bold shadow-lg hover:shadow-xl transition duration-300 p-2"
              onClick={() => navigate(routesnkt.postads)}
            >
              {t('post-ads')}
            </Button>

            <div>
              <Badge
                color="red"
                count={
                  pinnedCard?.booked
                    ? Object.values(
                        Object.fromEntries(
                          Object.entries(pinnedCard.booked).filter(
                            ([, value]) => value === true, // Ignore the key, only check the value
                          ),
                        ),
                      ).length
                    : 0 // Default to 0 if pinnedCard.booked is null or undefined
                }
              >
                <Link to={routesnkt.pinpage}>
                  <Button
                    type="primary"
                    icon={<FaBookmark />}
                    className="gap-4 rounded-full  text-[#0f2ec4] bg-white shadow-xl"
                  />
                </Link>
              </Badge>
            </div>

            {isLoginVisible && (
              <Dropdown
                menu={menu}
                trigger={['click']}
                placement="bottomRight"
                overlayStyle={{ marginRight: 10 }}
              >
                <Button
                  type="default"
                  shape="circle"
                  icon={<GoPerson className="text-[#0f2ec4] !text-2xl" />}
                  className="cursor-pointer"
                />
              </Dropdown>
            )}
          </div>
        )}
      </div>

      <Modal visible={isModalOpen} onCancel={handleDeleteClose} footer={false}>
        {modalType === 'password' ? (
          <div>
            <Form layout="vertical" form={form} onFinish={changePassword}>
              <Typography.Title level={4}>
                {' '}
                {t('change password')}{' '}
              </Typography.Title>
              <Form.Item
                label={t('old password')}
                name="oldPassword"
                rules={[
                  { required: true, message: t('please-input-your-password') },
                  {
                    pattern:
                      // eslint-disable-next-line no-useless-escape
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,30}$/,
                    message: t(
                      'password-must-include-uppercase-lowercase-number-and-special-character',
                    ),
                  },
                ]}
              >
                <InputPassword style={{ borderColor: '#0f2ec4' }} />
              </Form.Item>
              <Form.Item
                label={t('new password')}
                name="password"
                rules={[
                  { required: true, message: t('please-input-your-password') },
                  {
                    pattern:
                      // eslint-disable-next-line no-useless-escape
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,30}$/,
                    message: t(
                      'password-must-include-uppercase-lowercase-number-and-special-character',
                    ),
                  },
                ]}
              >
                <InputPassword style={{ borderColor: '#0f2ec4' }} />
              </Form.Item>
              <Form.Item
                label={t('confirm password')}
                name="newPassword"
                dependencies={['password']}
                rules={[
                  {
                    required: true,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          'The new password that you entered do not match!',
                        ),
                      );
                    },
                  }),
                ]}
              >
                <InputPassword />
              </Form.Item>
              <div className="flex justify-end">
                <Button
                  className="bg-[#0f2ec4] flex text-white rounded"
                  onClick={changePassword}
                >
                  {t('change password')}
                </Button>
              </div>
            </Form>
          </div>
        ) : (
          <>
            <p className="mb-4">
              {t(
                'are you sure you want to delete your account? You wont be able to recover it after 30 days',
              )}
            </p>

            <label>{t('email')}</label>

            <Input
              type="email"
              placeholder="Email"
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value.trim() })
              }
            />

            <label>{t('password-0')}</label>
            <InputPassword
              type="password"
              placeholder={t('password-1')}
              style={{ borderColor: '#0f2ec4' }}
              onChange={(e) =>
                setFormData({ ...formData, password: e.target.value.trim() })
              }
            />

            <div className="flex justify-end mt-2">
              <Button onClick={deleteAccount}>{t('deleteAccount')}</Button>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default Header;
